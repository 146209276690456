import React, { useEffect, useContext, useMemo } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { useDebouncedCallback } from 'use-debounce'
import { useAuth0 } from '@auth0/auth0-react'
import InputGroup from 'react-bootstrap/InputGroup'
import { ConstructorGetterContext, ConstructorSetterContext } from '../context/userContext'

const Wifi = () => {
  const { user, isAuthenticated } = useAuth0()
  const { wifiSsid, wifiPassword } = useContext(ConstructorGetterContext)
  const { setWifiSsid, setWifiPassword, setIsThereUnsavedChanges } =
    useContext(ConstructorSetterContext)

  const handleWifiSsidChange = (ssid) => {
    setWifiSsid(ssid)
    setIsThereUnsavedChanges(true)
  }

  const handleWifiPasswordChange = (password) => {
    setWifiPassword(password)
    setIsThereUnsavedChanges(true)
  }

  const isAdmin = useMemo(() => {
    if (user && isAuthenticated) {
      const userRolesArray = user[`${process.env.REACT_APP_BASE_URL}/roles`]
      return userRolesArray?.includes('admin')
    } else return false
  }, [user, isAuthenticated])

  return (
    <div id='content-container' className='content-container top-line'>
      <Row className='measure-title-container'>
        <Col className='measure-title' xl>
          <h5>Please enter your wifi credentials</h5>
        </Col>
      </Row>
      <Row className='wifi-input-container'>
        <Col id='rest-name' className='rest-name' xl>
          <InputGroup size='md'>
            <>
              <InputGroup.Prepend>
                <InputGroup.Text>SSID</InputGroup.Text>
              </InputGroup.Prepend>
              <input
                className='form-control'
                placeholder='Enter your WiFi network SSID'
                id='wifi-ssid-input'
                name='wifi-ssid'
                value={wifiSsid}
                onChange={(e) => handleWifiSsidChange(e.target.value)}
              />
            </>
          </InputGroup>
        </Col>
      </Row>
      <Row className='wifi-input-container mt-2'>
        <Col id='rest-name' className='rest-name' xl>
          <InputGroup size='md'>
            <>
              <InputGroup.Prepend>
                <InputGroup.Text>Password (optional)</InputGroup.Text>
              </InputGroup.Prepend>
              <input
                className='form-control'
                placeholder='Enter your WiFi network password'
                id='wifi-password-input'
                name='wifi-password'
                value={wifiPassword}
                onChange={(e) => handleWifiPasswordChange(e.target.value)}
              />
            </>
          </InputGroup>
        </Col>
      </Row>
    </div>
  )
}

export default Wifi

{
  /* <div className='flex items-center gap-2'>
            <div>Wifi:</div>
            <div>
              <div>SSID:</div>
              <input
                className='px-3 py-2 border border-gray-600 hover:border-gray-700 rounded-md'
                value={wifiSsid}
                onChange={(e) => handleWifiSsidChange(e.target.value)}
              />
            </div>
            <div>
              <div>Password:</div>
              <input
                className='px-3 py-2 border border-gray-600 hover:border-gray-700 rounded-md'
                value={wifiPassword}
                onChange={(e) => handleWifiPasswordChange(e.target.value)}
              />
            </div>
          </div> */
}

const uuid = require('uuid').v4

module.exports = {
  id: uuid(),
  url: '#',
  alias: '',
  isDeleted: false,
  events: {
    createdAt: null,
    editedAt: null,
    publishedAt: null,
    buildedAt: null,
    failedAt: null,
  },
  splashBgColor: '#252525',
  mainBgColor: '#ffffff',
  splashTextColor: '#ffffff',
  mainTextColor: '#000000',
  defaultLanguage: '',
  socials: {
    facebook: '',
    instagram: '',
    tripadvisor: '',
    whatsapp: '',
    telegram: '',
    tikTok: '',
    linkTree: '',
    googleMaps: '',
    openTable: '',
    website: '',
    email: '',
    customLink: '',
  },
  categoriesList: 'main',
  logo: '',
  templateName: 'default',
  wifi: {
    ssid: '',
    password: '',
  },
  importedTranslations: [],
  content: [
    {
      lang: '',
      isPublished: false,
      restName: '',
      menuName: 'menu',
      measureUnits: {
        mass: 'g',
        volume: 'ml',
        time: 'min',
        quantity: 'bottle',
        currency: '$',
        diameter: 'inches',
        length: 'cm',
      },
      subItems: [
        {
          id: uuid(),
          isVisible: true,
          catName: '',
          isFrozen: false,
          subItems: [
            {
              id: uuid(),
              isVisible: true,
              subcatName: '',
              subItems: [
                {
                  id: uuid(),
                  isVisible: true,
                  itemName: '',
                  description: '',
                  weight: '100',
                  measure: 'm',
                  price: '99',
                  sku: '',
                  handle: '',
                  image: '',
                  subVariant1: {
                    name: '',
                    values: [],
                  },
                  subVariant2: {
                    name: '',
                    values: [],
                  },
                  subVariant3: {
                    name: '',
                    values: [],
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}

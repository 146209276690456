import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter, Prompt } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import MenuItemConstructor from '../MenuItemConstructor'
import MenuItemViewer from '../MenuItemViewer'
import { ModalRemove } from '../shared/Modals'
import {
  RootGetterContext,
  RootSetterContext,
  ConstructorGetterContext,
  ConstructorSetterContext,
  MenuLocalsGetterContext,
  MenuLocalsSetterContext,
} from '../../context/userContext'
import { getMenu, updateShowList } from '../../helpers/index'
import { ntfErrorWithLink } from '../../helpers/notifications'
import GuideTour from '../GuideTour'
import ExportBlock from '../ExportBlock'
import { useIsDesktop } from '../../helpers/hooks'
import blankMenu from '../../data/blankMenu'

const { REACT_APP_JWT_AUDIENCE, REACT_APP_AUTH0_SCOPE } = process.env

const MenuPage = ({ match, history }) => {
  const { menuLocals } = useContext(MenuLocalsGetterContext)
  const { setMenuLocals } = useContext(MenuLocalsSetterContext)
  const { token, showRemoveModal } = useContext(RootGetterContext)
  const { setToken, setIsLoadingGlobal } = useContext(RootSetterContext)
  const { isThereUnsavedChanges, isPreviewOpen } = useContext(ConstructorGetterContext)
  const {
    setMenuId,
    setAlias,
    setDefaultLanguage,
    setCurrentLanguage,
    setEvents,
    setIsDeleted,
    setLogo,
    setMainBgColor,
    setMainTextColor,
    setSplashBgColor,
    setSplashTextColor,
    setSocials,
    setShowList,
    setTemplateName,
    setWifiSsid,
    setWifiPassword,
    setImportedTranslations,
  } = useContext(ConstructorSetterContext)
  const { user, getAccessTokenSilently } = useAuth0()
  const { t } = useTranslation()
  const isDesktop = useIsDesktop()

  const [fullMenuData, setFullMenuData] = useState(null)

  const menuId = match.params?.menuid

  useEffect(() => {
    ;(async () => {
      setIsLoadingGlobal(true)
      try {
        if (!token) {
          const accessToken = await getAccessTokenSilently({
            audience: REACT_APP_JWT_AUDIENCE,
            scope: REACT_APP_AUTH0_SCOPE,
          })
          setToken(accessToken)
        } else {
          const menuData = await getMenu(token, user.sub, menuId)
          if (menuData.success) {
            setFullMenuData(menuData.data)
            updateShowList(menuData.data, setShowList)
          } else {
            history.push('/my')
          }
        }
      } catch (e) {
        ntfErrorWithLink({
          title: 'getMenu error',
          message: e.message,
          link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
        })
        console.error('getMenu error:\n', e)
      } finally {
        setIsLoadingGlobal(false)
      }
    })()
    // eslint-disable-next-line
  }, [token])

  useEffect(() => {
    if (fullMenuData) {
      setMenuId(fullMenuData.id)
      setAlias(fullMenuData.alias)
      setDefaultLanguage(fullMenuData.defaultLanguage)
      setCurrentLanguage(fullMenuData.defaultLanguage)
      setEvents(fullMenuData.events)
      setIsDeleted(fullMenuData.isDeleted)
      setLogo(fullMenuData.logo)
      setMainBgColor(fullMenuData.mainBgColor)
      setMainTextColor(fullMenuData.mainTextColor)
      setSplashBgColor(fullMenuData.splashBgColor)
      setSplashTextColor(fullMenuData.splashTextColor)
      setSocials(fullMenuData.socials)
      setTemplateName(fullMenuData.templateName || 'default')
      setWifiSsid(fullMenuData.wifi?.ssid || '')
      setWifiPassword(fullMenuData.wifi?.password || '')
      updateShowList(fullMenuData, setShowList)
      Object.keys(blankMenu.content[0].measureUnits).forEach((key) => {
        // Check if the measure unit key exists in the fullMenuData, if not, add with default value
        if (!fullMenuData.content[0].measureUnits.hasOwnProperty(key)) {
          fullMenuData.content[0].measureUnits[key] = blankMenu.content[0].measureUnits[key]
        }
      })

      setMenuLocals(fullMenuData.content)
      setImportedTranslations(fullMenuData.importedTranslations)
    }
  }, [
    fullMenuData,
    setMenuId,
    setAlias,
    setDefaultLanguage,
    setCurrentLanguage,
    setEvents,
    setIsDeleted,
    setLogo,
    setMainBgColor,
    setMainTextColor,
    setSplashBgColor,
    setSplashTextColor,
    setSocials,
    setShowList,
    setTemplateName,
    setImportedTranslations,
    setMenuLocals,
    setWifiSsid,
    setWifiPassword,
  ])

  useEffect(() => {
    const body = document?.body
    if (!body) return
    if (!isDesktop) {
      if (isPreviewOpen) {
        body.style.overflowY = 'hidden'
      } else {
        body.style.overflowY = ''
      }
    } else {
      body.style.overflowY = ''
    }
  }, [isDesktop, isPreviewOpen])

  return (
    <>
      <Prompt when={isThereUnsavedChanges} message={t('MESSAGE_PROMPT_ARE_YOU_SURE')} />
      <Container className='main-view menu-page' fluid>
        <Row className='inner'>
          <Col className='constructor-container' sm>
            <MenuItemConstructor />
          </Col>
          <Col className='viewer-container' sm='auto'>
            <MenuItemViewer />
          </Col>
        </Row>
        {!isDesktop && <ExportBlock />}
      </Container>
      {isDesktop && menuLocals?.length && <GuideTour />}
      {showRemoveModal && <ModalRemove />}
    </>
  )
}

export default withRouter(MenuPage)

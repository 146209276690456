import React, { useState, useContext, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth0 } from '@auth0/auth0-react'
import Skeleton from 'react-loading-skeleton'
// import qr from 'qr-image'
import qr from 'qrcode'
import JSZip from 'jszip'
import html2pdf from 'html2pdf.js'
import {
  getIndicator,
  getUser,
  deleteMenu,
  getMenu,
  getWifiQRString,
  blobToDataURL,
} from '../helpers'
import { RootGetterContext, RootSetterContext, ManagerSetterContext } from '../context/userContext'
import MenuLanguageBlock from './MenuLanguageBlock'
import { fullList as availableLanguages } from '../data/langList'
import { ntfErrorWithLink } from '../helpers/notifications'
import icons from '../img/icons/index'
import { useIsDesktop } from '../helpers/hooks'
import DefaultLogo from '../img/DefaultLogo'
import QRCodeStyling from 'qr-code-styling'
import CameraIcon from '../img/camera-icon.svg'

const { REACT_APP_CLOUDINARY_LOGO_PATH } = process.env

const PDF_HEIGHT = 910
const PDF_WIDTH_A4 = 730
const PDF_TOP_MARGIN = 100

const PDF_HEIGHT_A5 = 620
const PDF_WIDTH_A5 = 470
const PDF_TOP_MARGIN_A5 = 80

const zip = new JSZip()

const { IconCloneMenu, IconPen, IconRemoveItem } = icons

const SOCIALS_ICONS = [
  { name: 'Website', icon: 'website.svg' },
  { name: 'Facebook', icon: 'facebook.svg' },
  { name: 'Instagram', icon: 'instagram.svg' },
]

const TheIndicator = ({ events }) => {
  const { t, i18n } = useTranslation()
  const [itemIndicator, setItemIndicator] = useState(null)
  let timeout = null

  const autoUpdateIndicator = async () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    setItemIndicator(getIndicator(events, i18n.language, t))
    timeout = setTimeout(async () => {
      autoUpdateIndicator()
    }, process.env.REACT_APP_MANAGER_INDICATOR_REFRESH_RATE)
  }

  useEffect(() => {
    if (events) {
      autoUpdateIndicator()
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [events, i18n.language])

  return (
    <div className='updated-at'>
      {itemIndicator ? (
        <>
          {itemIndicator?.map((item, i) => (
            <h4 key={i}>{item}</h4>
          ))}
        </>
      ) : (
        <h4>
          <Skeleton />
        </h4>
      )}
    </div>
  )
}

const MenuItem = ({ history, data }) => {
  const { token } = useContext(RootGetterContext)
  const { setShowRemoveModal, isLoadingGlobal, setIsLoadingGlobal } = useContext(RootSetterContext)
  const { setUserObject, setShowAliasOnlyModal } = useContext(ManagerSetterContext)
  const { user } = useAuth0()
  const { t } = useTranslation()
  const isDesktop = useIsDesktop()
  const usePDFMenu = useRef(null)
  const useMenuItem = useRef(null)

  const [isOpen, setIsOpen] = useState(false)
  const [pdfWidth, setPdfWidth] = useState(PDF_WIDTH_A4)

  const { id, logo, events, translations, alias, defaultLanguage } = data ?? {}
  const url = alias && `https://${alias}.gimme.menu`

  const handleRedirect = () => {
    history.push(`/my/${id}`)
  }

  const handleCloneMenu = async () => {
    if (isLoadingGlobal) return
    setShowAliasOnlyModal(id)
  }

  const handleDeleteMenu = async () => {
    if (isLoadingGlobal) return
    setIsLoadingGlobal(true)
    try {
      await deleteMenu(token, user.sub, id)
      const userData = await getUser(token, user.sub)
      if (userData.success) {
        setUserObject(userData.data)
      } else {
        ntfErrorWithLink({
          title: t('MESSAGE_ERROR_TITLE'),
          link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
        })
      }
      return userData
    } catch (e) {
      console.error(`handleDeleteMenu error:\n${e.toString()}`)
      ntfErrorWithLink({
        title: t('MESSAGE_ERROR_TITLE'),
        link: { caption: t('MESSAGE_GO_TO_HOME_PAGE'), url: '/' },
      })
    } finally {
      setIsLoadingGlobal(false)
    }
  }

  const dataURLtoBlob = (dataurl) => {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new Blob([u8arr], { type: mime })
  }

  const handleSaveQrZip = async () => {
    let filenamesArray = []
    for (let i = 0; i < translations.length; i++) {
      const urlToEncode =
        translations[i].lang !== defaultLanguage ? `${url}?lang=${translations[i].lang}` : url

      const qrcodePngString = await qr.toDataURL(urlToEncode, {
        type: 'utf8',
        scale: 10,
        margin: 1,
      })

      const pngBlob = dataURLtoBlob(qrcodePngString)

      const svgString = await qr.toString(urlToEncode, {
        type: 'svg',
      })
      const insertIndex = svgString.indexOf('><')
      const svgFilled = svgString
        .slice(0, insertIndex + 1)
        .concat(
          '<rect x="0" y="0" width="310" height="310" style="fill:#ffffff"/>',
          svgString.slice(insertIndex + 1)
        )
      // eslint-disable-next-line
      const svgBlob = new Blob([svgFilled], {
        type: 'image/svg+xml;charset=utf-8',
      })

      let svgName
      availableLanguages.forEach((lang) => {
        if (translations[i].lang === lang.value) {
          svgName = translations[i].lang === defaultLanguage ? `${lang.name}(default)` : lang.name
        }
      })
      zip.file(`menu_${svgName}.svg`, svgBlob)
      zip.file(`menu_${svgName}.png`, pngBlob)
      filenamesArray.push(`menu_${svgName}.svg`, `menu_${svgName}.png`)
    }

    const zipResult = await zip.generateAsync({ type: 'blob' })
    const a = document.createElement('a')
    a.href = URL.createObjectURL(zipResult)
    a.setAttribute('download', 'menu-qr-codes.zip')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    filenamesArray.forEach((item) => zip.remove(item))
  }

  const packMd = (locale, includeHidden = false, includeSku = false) => {
    let md = ``

    for (const category of locale.subItems) {
      if (!includeHidden && !category.isVisible && !category.subItems?.length) {
        continue
      }

      // Check if category has any visible items
      const hasVisibleItems = category.subItems.some(
        (subcat) => subcat.isVisible && subcat.subItems?.some((item) => item.isVisible)
      )

      if (hasVisibleItems) {
        md += '\n<div style="page-break-inside: avoid;" markdown="1">\n'
        md += category.catName ? `\n\n## ${category.catName}` : ''
      } else {
        md += category.catName ? `\n\n## ${category.catName}` : ''
      }

      for (const subcategory of category.subItems) {
        if (!includeHidden && !subcategory.isVisible && !subcategory.subItems?.length) {
          continue
        }

        md += '\n<div style="page-break-inside: avoid;" markdown="1">\n'
        md += subcategory.subcatName ? `\n### ${subcategory.subcatName}\n` : ''

        for (const item of subcategory.subItems) {
          if (!includeHidden && !item.isVisible && !item.subItems?.length) {
            continue
          }

          const hasSubVariants =
            item.subVariant1?.values?.length ||
            item.subVariant2?.values?.length ||
            item.subVariant3?.values?.length

          const mainPrice = hasSubVariants ? '' : item.price
          const mainName =
            includeSku && !hasSubVariants ? `#${item.sku} ${item.itemName}` : item.itemName

          md += item.itemName
            ? `\n- **${mainName}**${
                mainPrice ? ` - ${mainPrice} ${locale.measureUnits.currency}` : ''
              }`
            : ''

          if (item.subVariant1?.values?.length) {
            md += `\n  ${item.subVariant1.values
              .map(
                (v) =>
                  `${includeSku ? `#${v.sku} ` : ''}${v.subVariantName} - ${v.price} ${
                    locale.measureUnits.currency
                  }`
              )
              .join(' | ')}`
          }

          if (item.subVariant2?.values?.length) {
            md += `\n  ${item.subVariant2.values
              .map(
                (v) =>
                  `${includeSku ? `#${v.sku} ` : ''}${v.subVariantName} - ${v.price} ${
                    locale.measureUnits.currency
                  }`
              )
              .join(' | ')}`
          }

          if (item.subVariant3?.values?.length) {
            md += `\n  ${item.subVariant3.values
              .map(
                (v) =>
                  `${includeSku ? `#${v.sku} ` : ''}${v.subVariantName} - ${v.price} ${
                    locale.measureUnits.currency
                  }`
              )
              .join(' | ')}`
          }

          if (item.description) {
            md += `\n  - *${item.description}*`
          }
        }
        md += '\n</div>\n'
      }

      if (hasVisibleItems) {
        md += '\n</div>\n'
      }
    }

    return md
  }

  const packMdForKitchen = (locale, includeHidden = false) => {
    let md = '| SKU | Item Name | Price |\n|-----|-----------|-------|\n'

    for (const category of locale.subItems) {
      if (!includeHidden && !category.isVisible) continue

      for (const subcategory of category.subItems) {
        if (!includeHidden && !subcategory.isVisible) continue

        for (const item of subcategory.subItems) {
          if (!includeHidden && !item.isVisible) continue

          const hasSubvariants =
            item.subVariant1?.values?.length ||
            item.subVariant2?.values?.length ||
            item.subVariant3?.values?.length

          md += `| ${item.sku} | ${item.itemName} | ${
            hasSubvariants ? '' : `${item.price} ${locale.measureUnits.currency}`
          } |\n`

          if (item.subVariant1?.values?.length) {
            item.subVariant1.values.forEach((variant) => {
              md += `| ${variant.sku} | ↳ ${variant.subVariantName} | ${variant.price} ${locale.measureUnits.currency} |\n`
            })
          }

          if (item.subVariant2?.values?.length) {
            item.subVariant2.values.forEach((variant) => {
              md += `| ${variant.sku} | ↳ ${variant.subVariantName} | ${variant.price} ${locale.measureUnits.currency} |\n`
            })
          }

          if (item.subVariant3?.values?.length) {
            item.subVariant3.values.forEach((variant) => {
              md += `| ${variant.sku} | ↳ ${variant.subVariantName} | ${variant.price} ${locale.measureUnits.currency} |\n`
            })
          }
        }
      }
    }

    return md
  }

  const handleSaveMdZip = async () => {
    const transformData = (data, includeHidden = false) => {
      let mds = []

      for (const locale of data.content) {
        const mdWithoutSkuInName = packMd(locale, includeHidden, false)
        const mdWithSkuInName = packMd(locale, includeHidden, true)
        const mdForKitchen = packMdForKitchen(locale, includeHidden)
        mds.push({ lang: locale.lang, md: mdWithoutSkuInName, includeSku: false })
        mds.push({ lang: locale.lang, md: mdWithSkuInName, includeSku: true })
        mds.push({ lang: locale.lang, md: mdForKitchen, includeSku: false, isKitchen: true })
      }

      return mds
    }

    const currentMenu = await getMenu(token, user.sub, id)
    const mds = transformData(currentMenu.data)

    let filenamesArray = []
    for (const md of mds) {
      const mdLang = md.lang === defaultLanguage ? `${md.lang}_default` : md.lang
      const mdFileName = `menu_${md.includeSku ? 'sku_' : ''}${
        md.isKitchen ? 'kitchen_' : ''
      }${mdLang}.md`
      const mdBlob = new Blob([md.md], {
        type: 'text/markdown;charset=utf-8',
      })
      zip.file(mdFileName, mdBlob)
      filenamesArray.push(mdFileName)
    }

    const zipResult = await zip.generateAsync({ type: 'blob' })
    const a = document.createElement('a')
    a.href = URL.createObjectURL(zipResult)
    a.setAttribute('download', 'menu-markdowns.zip')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    filenamesArray.forEach((item) => zip.remove(item))
  }

  const handleSavePdfZip = async () => {
    setPdfWidth(PDF_WIDTH_A4)
    const currentMenu = await getMenu(token, user.sub, id)
    if (!currentMenu?.data?.content) {
      console.error('Content is undefined or missing in currentMenu')
      return
    }

    const fallbackContent = {
      ...currentMenu.data.content[0],
      subItems: currentMenu.data.content[0].subItems
        .filter((category) => category.isVisible)
        .map((category) => ({
          ...category,
          subItems: category.subItems
            .filter((subcategory) => subcategory.isVisible)
            .map((subcategory) => ({
              ...subcategory,
              subItems: subcategory.subItems.filter((item) => item.isVisible),
            })),
        })),
    }

    const allData = currentMenu.data.content.map((content) => {
      const data = content.subItems
        .filter((category) => category.isVisible)
        .map((category, categoryIndex) => ({
          catName: category.catName || fallbackContent.subItems[categoryIndex]?.catName || '',
          subItems: category.subItems
            .filter((subCategory) => subCategory.isVisible)
            .map((subCategory, subCategoryIndex) => ({
              subcatName:
                subCategory.subcatName ||
                fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subcatName ||
                '',
              subItems: subCategory.subItems
                .filter((item) => item.isVisible)
                .map((item, itemIndex) => ({
                  itemName:
                    item.itemName ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.itemName ||
                    '',
                  description:
                    item.description ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.description ||
                    '',
                  price:
                    item.price ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.price ||
                    '',
                  subVariant1:
                    item.subVariant1 ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.subVariant1 ||
                    '',
                  subVariant2:
                    item.subVariant2 ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.subVariant2 ||
                    '',
                  subVariant3:
                    item.subVariant3 ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.subVariant3 ||
                    '',
                })),
            })),
        }))
        .flat()

      return {
        data,
        menuName: content.menuName || fallbackContent?.menuName,
        lang: content.lang,
        templateName: currentMenu.data.templateName,
        wifi: currentMenu.data.wifi,
      }
    })

    let pdfsBlob = []

    for (const { data, menuName, lang, templateName, wifi } of allData) {
      const getContentHeight = (content) => {
        const tempDiv = document.createElement('div')
        tempDiv.style.position = 'absolute'
        tempDiv.style.visibility = 'hidden'
        tempDiv.style.width = '100%'
        tempDiv.style.top = '-9999px'
        tempDiv.innerHTML = content
        document.body.appendChild(tempDiv)
        const height = tempDiv.offsetHeight
        document.body.removeChild(tempDiv)
        return height
      }

      const hasSubVariants = (subItem) => {
        return (
          subItem.subVariant1?.values?.length ||
          subItem.subVariant2?.values?.length ||
          subItem.subVariant3?.values?.length
        )
      }

      const createItemContent = (subItem, isThreeColumnLayout, isLastItem) => {
        const content = `
        <div class="max-w-[345px] w-full ${isLastItem ? 'mb-4' : 'mb-2'}">
          <div class="flex flex-row justify-between gap-5 items-center">
            <h4 class="font-lato text-black ${
              isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
            } font-bold leading-normal mb-0">${subItem.itemName}</h4>
            ${
              hasSubVariants(subItem)
                ? ''
                : subItem.price &&
                  `<p class="font-lato ${
                    isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
                  } text-black leading-normal whitespace-nowrap">${subItem.price} ${
                    currentMenu.data.content[0].measureUnits?.currency
                  }</p>`
            }
          </div>
    
          ${
            subItem.description &&
            `<p class="mt-1.5 ml-3 font-lato italic leading-normal ${
              isThreeColumnLayout ? 'text-[11px]' : 'text-[13px]'
            } text-gray-500">${subItem.description}</p>`
          }
          ${
            subItem.subVariant1 && subItem.subVariant1.values.length
              ? subItem.subVariant1.values
                  .map((variant) =>
                    variant.isVisible
                      ? `<div class="ml-3 flex justify-between gap-5 mt-1"><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
                        } text-black font-semibold leading-normal">${
                          variant.subVariantName
                        }</p><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
                        } text-black font-normal leading-normal whitespace-nowrap">${
                          variant.price
                        } ${currentMenu.data.content[0].measureUnits?.currency}</p></div>`
                      : ''
                  )
                  .join('')
              : ''
          }
          ${
            subItem.subVariant2 && subItem.subVariant2.values.length
              ? subItem.subVariant2.values
                  .map((variant) =>
                    variant.isVisible
                      ? `<div class="ml-3 flex justify-between gap-5 mt-1"><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
                        } text-black font-semibold leading-normal">${
                          variant.subVariantName
                        }</p><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
                        } text-black font-normal leading-normal whitespace-nowrap">${
                          variant.price
                        } ${currentMenu.data.content[0].measureUnits?.currency}</p></div>`
                      : ''
                  )
                  .join('')
              : ''
          }
          ${
            subItem.subVariant3 && subItem.subVariant3.values.length
              ? subItem.subVariant3.values
                  .map((variant) =>
                    variant.isVisible
                      ? `<div class="ml-3 flex justify-between gap-5 mt-1"><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
                        } text-black font-semibold leading-normal">${
                          variant.subVariantName
                        }</p><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
                        } text-black font-normal leading-normal whitespace-nowrap">${
                          variant.price
                        } ${currentMenu.data.content[0].measureUnits?.currency}</p></div>`
                      : ''
                  )
                  .join('')
              : ''
          }
        </div>
      `
        return {
          content,
          height: getContentHeight(content),
        }
      }

      const distributeContentAcrossPages = (data) => {
        const pages = []
        let currentPage = {
          data: [[], [], []],
          isThreeColumnLayout: false,
          isFullyFilled: [false, false, false],
        }
        let currentPageHeight = [0, 0, 0]
        const maxPageHeight = PDF_HEIGHT
        let isThreeColumnLayout = false

        data.forEach((category) => {
          const categoryHeader = category?.catName
            ? `<h1 class="ml-0 max-w-[345px] w-full font-lato text-black ${
                isThreeColumnLayout ? 'text-[15px]' : 'text-[17px]'
              } font-extrabold leading-normal mt-0 pb-2 border-b border-b-gray-300 mb-3">${
                category.catName
              }</h1>`
            : null
          const categoryHeaderHeight = getContentHeight(categoryHeader)
          let categoryAdded = false

          // console.log(`Processing category: ${category.catName}, Height: ${categoryHeaderHeight}`)

          const currentCategoryIsThreeColumnLayout =
            category.catName === 'Drinks' || category.catName === 'Spirits'

          if (currentCategoryIsThreeColumnLayout !== isThreeColumnLayout) {
            if (
              currentPage.data[0].length > 0 ||
              currentPage.data[1].length > 0 ||
              currentPage.data[2].length > 0
            ) {
              // console.log('Starting a new page due to layout change.')
              pages.push(currentPage)
              currentPage = {
                data: [[], [], []],
                isThreeColumnLayout: currentCategoryIsThreeColumnLayout,
                isFullyFilled: [false, false, false],
              }
              currentPageHeight = [0, 0, 0]
            }
            isThreeColumnLayout = currentCategoryIsThreeColumnLayout
          }

          category.subItems.forEach((item) => {
            const subItemHeader = item?.subcatName
              ? `<h3 class="ml-0 max-w-[345px] w-full font-lato text-black ${
                  isThreeColumnLayout ? 'text-[15px]' : 'text-[17px]'
                } leading-normal pb-1.5 border-b border-b-gray-300 mt-0 mb-2">${
                  item.subcatName
                }</h3>`
              : null
            const subItemHeaderHeight = getContentHeight(subItemHeader)
            let subCategoryAdded = false

            // console.log(
            //   `Processing subcategory: ${item.subcatName}, Height: ${subItemHeaderHeight}`
            // )

            item.subItems.forEach((subItem, index) => {
              const isLastItem = index === item.subItems.length - 1

              const subItemContentObj = createItemContent(subItem, isThreeColumnLayout, isLastItem)

              // console.log(
              //   `Processing item: ${subItem.itemName}, Height: ${subItemContentObj.height}`
              // )

              const totalHeightColumn1 =
                currentPageHeight[0] +
                subItemContentObj.height +
                (!subCategoryAdded ? subItemHeaderHeight : 0) +
                (!categoryAdded ? categoryHeaderHeight : 0)
              const totalHeightColumn2 =
                currentPageHeight[1] +
                subItemContentObj.height +
                (!subCategoryAdded ? subItemHeaderHeight : 0) +
                (!categoryAdded ? categoryHeaderHeight : 0)
              const totalHeightColumn3 =
                currentPageHeight[2] +
                subItemContentObj.height +
                (!subCategoryAdded ? subItemHeaderHeight : 0) +
                (!categoryAdded ? categoryHeaderHeight : 0)

              if (isThreeColumnLayout) {
                if (totalHeightColumn1 <= maxPageHeight && !currentPage.isFullyFilled[0]) {
                  // console.log(`Adding to column 1. New height: ${totalHeightColumn1}`)
                  if (!categoryAdded) {
                    currentPage.data[0].push(categoryHeader)
                    currentPageHeight[0] += categoryHeaderHeight
                    categoryAdded = true
                  }
                  if (!subCategoryAdded) {
                    currentPage.data[0].push(subItemHeader)
                    currentPageHeight[0] += subItemHeaderHeight
                    subCategoryAdded = true
                  }
                  currentPage.data[0].push(subItemContentObj.content)
                  currentPageHeight[0] += subItemContentObj.height
                  if (currentPageHeight[0] >= maxPageHeight) {
                    currentPage.isFullyFilled[0] = true
                  }
                } else if (totalHeightColumn2 <= maxPageHeight && !currentPage.isFullyFilled[1]) {
                  // console.log(`Adding to column 2. New height: ${totalHeightColumn2}`)
                  if (!categoryAdded) {
                    currentPage.data[1].push(categoryHeader)
                    currentPageHeight[1] += categoryHeaderHeight
                    categoryAdded = true
                  }
                  if (!subCategoryAdded) {
                    currentPage.data[1].push(subItemHeader)
                    currentPageHeight[1] += subItemHeaderHeight
                    subCategoryAdded = true
                  }
                  currentPage.data[1].push(subItemContentObj.content)
                  currentPageHeight[1] += subItemContentObj.height
                  if (currentPageHeight[1] >= maxPageHeight) {
                    currentPage.isFullyFilled[1] = true
                  }
                  currentPage.isFullyFilled[0] = true // Mark previous column as filled
                } else if (totalHeightColumn3 <= maxPageHeight && !currentPage.isFullyFilled[2]) {
                  // console.log(`Adding to column 3. New height: ${totalHeightColumn3}`)
                  if (!categoryAdded) {
                    currentPage.data[2].push(categoryHeader)
                    currentPageHeight[2] += categoryHeaderHeight
                    categoryAdded = true
                  }
                  if (!subCategoryAdded) {
                    currentPage.data[2].push(subItemHeader)
                    currentPageHeight[2] += subItemHeaderHeight
                    subCategoryAdded = true
                  }
                  currentPage.data[2].push(subItemContentObj.content)
                  currentPageHeight[2] += subItemContentObj.height
                  if (currentPageHeight[2] >= maxPageHeight) {
                    currentPage.isFullyFilled[2] = true
                  }
                  currentPage.isFullyFilled[1] = true // Mark previous column as filled
                } else {
                  // console.log('Starting a new page.')
                  pages.push(currentPage)
                  currentPage = {
                    data: [[], [], []],
                    isThreeColumnLayout: isThreeColumnLayout,
                    isFullyFilled: [false, false, false],
                  }
                  currentPageHeight = [0, 0, 0]

                  // console.log(`Adding to column 1 of new page. Height: ${subItemContentObj.height}`)
                  currentPage.data[0].push(categoryHeader)
                  currentPage.data[0].push(subItemHeader)
                  currentPage.data[0].push(subItemContentObj.content)
                  currentPageHeight[0] +=
                    categoryHeaderHeight + subItemHeaderHeight + subItemContentObj.height
                  categoryAdded = true
                  subCategoryAdded = true
                }
              } else {
                if (totalHeightColumn1 <= maxPageHeight && !currentPage.isFullyFilled[0]) {
                  // console.log(`Adding to column 1. New height: ${totalHeightColumn1}`)
                  if (!categoryAdded) {
                    currentPage.data[0].push(categoryHeader)
                    currentPageHeight[0] += categoryHeaderHeight
                    categoryAdded = true
                  }
                  if (!subCategoryAdded) {
                    currentPage.data[0].push(subItemHeader)
                    currentPageHeight[0] += subItemHeaderHeight
                    subCategoryAdded = true
                  }
                  currentPage.data[0].push(subItemContentObj.content)
                  currentPageHeight[0] += subItemContentObj.height
                  if (currentPageHeight[0] >= maxPageHeight) {
                    currentPage.isFullyFilled[0] = true
                  }
                } else if (totalHeightColumn2 <= maxPageHeight && !currentPage.isFullyFilled[1]) {
                  // console.log(`Adding to column 2. New height: ${totalHeightColumn2}`)
                  if (!categoryAdded) {
                    currentPage.data[1].push(categoryHeader)
                    currentPageHeight[1] += categoryHeaderHeight
                    categoryAdded = true
                  }
                  if (!subCategoryAdded) {
                    currentPage.data[1].push(subItemHeader)
                    currentPageHeight[1] += subItemHeaderHeight
                    subCategoryAdded = true
                  }
                  currentPage.data[1].push(subItemContentObj.content)
                  currentPageHeight[1] += subItemContentObj.height
                  if (currentPageHeight[1] >= maxPageHeight) {
                    currentPage.isFullyFilled[1] = true
                  }
                  currentPage.isFullyFilled[0] = true // Mark previous column as filled
                } else {
                  // console.log('Starting a new page.')
                  pages.push(currentPage)
                  currentPage = {
                    data: [[], [], []],
                    isThreeColumnLayout: isThreeColumnLayout,
                    isFullyFilled: [false, false, false],
                  }
                  currentPageHeight = [0, 0, 0]

                  // console.log(`Adding to column 1 of new page. Height: ${subItemContentObj.height}`)
                  currentPage.data[0].push(categoryHeader)
                  currentPage.data[0].push(subItemHeader)
                  currentPage.data[0].push(subItemContentObj.content)
                  currentPageHeight[0] +=
                    categoryHeaderHeight + subItemHeaderHeight + subItemContentObj.height
                  categoryAdded = true
                  subCategoryAdded = true
                }
              }
            })
          })
        })

        if (
          currentPage.data[0].length > 0 ||
          currentPage.data[1].length > 0 ||
          currentPage.data[2].length > 0
        ) {
          // console.log('Adding the last page.')
          pages.push(currentPage)
        }

        // console.log('Total pages:', pages.length)
        return pages
      }

      const pages = distributeContentAcrossPages(data)

      const createPageContent = (page, pageIndex) => {
        const { data, isThreeColumnLayout } = page
        return `
        <div style="height: ${PDF_HEIGHT}px; margin-top: ${PDF_TOP_MARGIN}px;" class='break-after-page relative'>
          <div class="flex flex-row justify-between gap-10">
            <div class="${isThreeColumnLayout ? 'w-[32%]' : 'w-[48%]'} box-border">${data[0].join(
          ''
        )}</div>
            <div class="${isThreeColumnLayout ? 'w-[32%]' : 'w-[48%]'} box-border">${data[1].join(
          ''
        )}</div>
            ${
              isThreeColumnLayout ? `<div class="w-[32%] box-border">${data[2].join('')}</div>` : ''
            }
          </div>
          <div class="text-center absolute -bottom-20 border-t border-t-gray-300 pt-2 left-0 w-full">
            <p class="text-right font-lato text-[9px] text-gray-500">Page ${pageIndex + 1}</p>
          </div>
        </div>
      `
      }

      const createPreviewPageContent = async () => {
        const publishedDate = new Date(
          currentMenu.data.events?.publishedAt ||
            currentMenu.data.events?.editedAt ||
            currentMenu.data.events?.createdAt
        )
        const day = String(publishedDate.getDate()).padStart(2, '0')
        const month = String(publishedDate.getMonth() + 1).padStart(2, '0')
        const year = publishedDate.getFullYear()
        const formattedDate = `${day}.${month}.${year}`

        const qrWifiString = await getWifiQRString(
          currentMenu?.data?.wifi?.ssid,
          currentMenu?.data?.wifi?.password
        )
        const qrWifi = await qr.toDataURL(qrWifiString, {
          type: 'image/png',
        })

        const qrMenuObject = new QRCodeStyling({
          width: 300,
          height: 300,
          type: 'canvas',
          data: `https://${currentMenu?.data?.alias}.gimme.menu`,
          qrOptions: { typeNumber: 0, errorCorrectionLevel: 'Q' },
          imageOptions: { crossOrigin: 'anonymous', imageSize: 0.3 },
          dotsOptions: { type: 'dots', color: '#000' },
          image: templateName === 'premium' ? CameraIcon : '',
          cornersSquareOptions: { type: 'square' },
          cornersDotOptions: { type: 'square' },
        })
        const qrMenu = await blobToDataURL(await qrMenuObject.getRawData('png'))

        const qrReview = currentMenu?.data?.socials?.customLink
          ? await qr.toDataURL(currentMenu?.data?.socials?.customLink, {
              type: 'image/png',
            })
          : ''

        const generateQR = async (content) => {
          return await qr.toDataURL(content, { type: 'image/png' })
        }

        const socialsHTML = await Promise.all(
          SOCIALS_ICONS.map(async (social, index) => {
            const socialName = social?.name
            const socialValue = currentMenu?.data?.socials?.[socialName.toLowerCase()]
            if (!socialName || !socialValue?.length) {
              return ''
            }

            const generatedQr = await generateQR(socialValue)
            return `
            <div key="${index}" class="flex flex-col items-center gap-2">
              <div class="flex items-center justify-center gap-1 relative z-10">
                <img class="mt-1 h-4 w-auto max-w-4" src="./socials/${
                  social?.icon
                }" alt="${socialName}" />
                <p class="mb-2 font-lato text-[11px] text-black leading-normal">${socialName}</p>
              </div>
              ${
                generatedQr
                  ? `<img class="w-[100px] h-[100px]" src="${generatedQr}" alt="qr" />`
                  : ''
              }
            </div>
          `
          })
        )

        return `
        <div
          style="height: ${PDF_HEIGHT}px;"
          class="break-after-page flex flex-col justify-between"
        >
          ${
            currentMenu?.data?.logo
              ? `<img class="mx-auto mt-16 max-w-[300px] w-full" src="https://res.cloudinary.com/gimmemenu/image/upload/f_png,w_500,c_pad/${currentMenu?.data?.logo}" alt="${currentMenu.data.content[0]?.restName}" />`
              : '&nbsp;'
          }
          <div>
            <div class="flex justify-between gap-8 max-w-2xl w-full mx-auto items-end">
              <div class="w-[140px] flex flex-col items-center">
                ${
                  currentMenu?.data?.wifi?.ssid
                    ? `
                      <div class="flex items-center justify-center gap-1 relative z-10">
                        <img class="mt-1 h-6 w-6" src="./preview/wifi.svg" alt="wifi" />
                        <p class="mb-3 font-lato text-[13px] text-black leading-normal">Scan for Wi-Fi</p>
                      </div>
                      <img class="w-[140px] h-[140px]" src=${qrWifi} alt="wifi" />
                      <div>
                        <p class="text-[10px] text-black font-lato text-center leading-normal">
                          Name: <span class="font-bold">${wifi?.ssid}</span>
                        </p>
                        ${
                          wifi?.password
                            ? `<p class="text-[10px] text-black font-lato text-center leading-normal">
                          Pass: <span class="font-bold">${wifi?.password}</span>
                        </p>`
                            : ''
                        }
                      </div>
                    `
                    : ''
                }
              </div>
      
              <div class="flex flex-col items-center">
                <div class="flex items-center justify-center gap-1 relative z-10">
                  <img class="mt-1 h-6 w-6" src="./preview/menu.svg" alt="menu" />
                  <p class="mb-3 font-lato text-[13px] text-black leading-normal">${
                    templateName === 'premium' ? 'Scan for Menu with Photos' : 'Scan for Menu'
                  }</p>
                </div>
                <img class="w-[240px] h-[240px]" src=${qrMenu} alt="menu" />
              </div>
      
              <div class="w-[140px] flex flex-col items-center">
                ${
                  currentMenu?.data?.socials?.customLink?.length
                    ? `
                      <div class="flex flex-col items-center">
                        <div class="flex items-center justify-center gap-1 relative z-10">
                          <img class="mt-1 h-6 w-6" src="./preview/reviews.svg" alt="reviews" />
                          <p class="mb-3 font-lato text-[13px] text-black leading-normal">Scan to Review</p>
                        </div>
                        <img class="w-[140px] h-[140px]" src=${qrReview} alt="reviews" />
                        <p class="text-[10px] text-black font-lato text-center leading-normal">
                          We will appreciate<br />your review :)
                        </p>
                      </div>
                    `
                    : ''
                }
              </div>
            </div>
      
            <div>
              <p class="text-center font-lato text-[9px] leading-normal text-black pb-3 border-b border-b-gray-600">
                Menu updated on ${formattedDate}
              </p>
              <div class="mt-10 flex justify-center gap-14">
                ${socialsHTML.join('')}
              </div>
            </div>
          </div>
        </div>
      `
      }

      const previewHtmlContent = await createPreviewPageContent()
      const htmlPages = pages.map((page, index) => createPageContent(page, index)).join('')
      const htmlContent = `<div>${previewHtmlContent}${htmlPages}</div>`

      usePDFMenu.current.innerHTML = htmlContent

      const pdfBlob = await html2pdf()
        .from(usePDFMenu.current)
        .set({
          margin: 0,
          filename: `${menuName}-${lang}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: {
            scale: 2,
            useCORS: true, // enable cross-origin
            allowTaint: false, // do not taint canvas
          },
          jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        })
        .outputPdf('blob')
      const fileName = menuName?.trim()
        ? `${menuName}-a4-full-${lang}.pdf`
        : `menu-a4-full-${lang}.pdf`

      pdfsBlob.push({
        name: fileName,
        blob: pdfBlob,
      })
    }

    return pdfsBlob
  }

  const handleSaveDrinksPdfZip = async () => {
    setPdfWidth(PDF_WIDTH_A4)
    const currentMenu = await getMenu(token, user.sub, id)

    if (!currentMenu?.data?.content) {
      console.error('Content is undefined or missing in currentMenu')
      return
    }

    const fallbackContent = {
      ...currentMenu.data.content[0],
      subItems: currentMenu.data.content[0].subItems
        .filter((category) => category.isVisible)
        .map((category) => ({
          ...category,
          subItems: category.subItems
            .filter((subcategory) => subcategory.isVisible)
            .map((subcategory) => ({
              ...subcategory,
              subItems: subcategory.subItems.filter((item) => item.isVisible),
            })),
        })),
    }

    const allData = currentMenu.data.content.map((content) => {
      const data = content.subItems
        .filter((category) => category.isVisible)
        .map((category, categoryIndex) => ({
          catName: category.catName || fallbackContent.subItems[categoryIndex]?.catName || '',
          subItems: category.subItems
            .filter((subCategory) => subCategory.isVisible)
            .map((subCategory, subCategoryIndex) => ({
              subcatName:
                subCategory.subcatName ||
                fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subcatName ||
                '',
              subItems: subCategory.subItems
                .filter((item) => item.isVisible)
                .map((item, itemIndex) => ({
                  itemName:
                    item.itemName ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.itemName ||
                    '',
                  description:
                    item.description ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.description ||
                    '',
                  price:
                    item.price ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.price ||
                    '',
                  subVariant1:
                    item.subVariant1 ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.subVariant1 ||
                    '',
                  subVariant2:
                    item.subVariant2 ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.subVariant2 ||
                    '',
                  subVariant3:
                    item.subVariant3 ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.subVariant3 ||
                    '',
                })),
            })),
        }))
        .flat()

      return {
        data,
        menuName: content.menuName || fallbackContent?.menuName,
        lang: content.lang,
        templateName: currentMenu.data.templateName,
        wifi: currentMenu.data.wifi,
      }
    })

    let pdfsBlob = []

    for (const { data, menuName, lang, templateName, wifi } of allData) {
      const getContentHeight = (content) => {
        const tempDiv = document.createElement('div')
        tempDiv.style.position = 'absolute'
        tempDiv.style.visibility = 'hidden'
        tempDiv.style.width = '100%'
        tempDiv.style.top = '-9999px'
        tempDiv.innerHTML = content
        document.body.appendChild(tempDiv)
        const height = tempDiv.offsetHeight
        document.body.removeChild(tempDiv)
        return height
      }

      const hasSubVariants = (subItem) => {
        return (
          subItem.subVariant1?.values?.length ||
          subItem.subVariant2?.values?.length ||
          subItem.subVariant3?.values?.length
        )
      }

      const createItemContent = (subItem, isThreeColumnLayout, isLastItem) => {
        const content = `
        <div class="max-w-[345px] w-full ${isLastItem ? 'mb-4' : 'mb-2'}">
          <div class="flex flex-row justify-between gap-5 items-center">
            <h4 class="font-lato text-black ${
              isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
            } font-bold leading-normal mb-0">${subItem.itemName}</h4>
            ${
              hasSubVariants(subItem)
                ? ''
                : subItem.price &&
                  `<p class="font-lato ${
                    isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
                  } text-black leading-normal whitespace-nowrap">${subItem.price} ${
                    currentMenu.data.content[0].measureUnits?.currency
                  }</p>`
            }
          </div>
    
          ${
            subItem.description &&
            `<p class="mt-1.5 ml-3 font-lato italic leading-normal ${
              isThreeColumnLayout ? 'text-[11px]' : 'text-[13px]'
            } text-gray-500">${subItem.description}</p>`
          }
          ${
            subItem.subVariant1 && subItem.subVariant1.values.length
              ? subItem.subVariant1.values
                  .map((variant) =>
                    variant.isVisible
                      ? `<div class="ml-3 flex justify-between gap-5 mt-1"><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
                        } text-black font-semibold leading-normal">${
                          variant.subVariantName
                        }</p><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
                        } text-black font-normal leading-normal whitespace-nowrap">${
                          variant.price
                        } ${currentMenu.data.content[0].measureUnits?.currency}</p></div>`
                      : ''
                  )
                  .join('')
              : ''
          }
          ${
            subItem.subVariant2 && subItem.subVariant2.values.length
              ? subItem.subVariant2.values
                  .map((variant) =>
                    variant.isVisible
                      ? `<div class="ml-3 flex justify-between gap-5 mt-1"><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
                        } text-black font-semibold leading-normal">${
                          variant.subVariantName
                        }</p><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
                        } text-black font-normal leading-normal whitespace-nowrap">${
                          variant.price
                        } ${currentMenu.data.content[0].measureUnits?.currency}</p></div>`
                      : ''
                  )
                  .join('')
              : ''
          }
          ${
            subItem.subVariant3 && subItem.subVariant3.values.length
              ? subItem.subVariant3.values
                  .map((variant) =>
                    variant.isVisible
                      ? `<div class="ml-3 flex justify-between gap-5 mt-1"><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
                        } text-black font-semibold leading-normal">${
                          variant.subVariantName
                        }</p><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
                        } text-black font-normal leading-normal whitespace-nowrap">${
                          variant.price
                        } ${currentMenu.data.content[0].measureUnits?.currency}</p></div>`
                      : ''
                  )
                  .join('')
              : ''
          }
        </div>
      `
        return {
          content,
          height: getContentHeight(content),
        }
      }

      const distributeContentAcrossPages = (data) => {
        const pages = []
        let currentPage = {
          data: [[], [], []],
          isThreeColumnLayout: true,
          isFullyFilled: [false, false, false],
        }
        let currentPageHeight = [0, 0, 0]
        const maxPageHeight = PDF_HEIGHT
        let isThreeColumnLayout = true

        data.forEach((category) => {
          if (category.catName === 'Drinks' || category.catName === 'Spirits') {
            const categoryHeader = category?.catName
              ? `<h1 class="ml-0 max-w-[345px] w-full font-lato text-black ${
                  isThreeColumnLayout ? 'text-[15px]' : 'text-[17px]'
                } font-extrabold leading-normal mt-0 pb-2 border-b border-b-gray-300 mb-3">${
                  category.catName
                }</h1>`
              : null
            const categoryHeaderHeight = getContentHeight(categoryHeader)
            let categoryAdded = false

            // console.log(`Processing category: ${category.catName}, Height: ${categoryHeaderHeight}`)

            const currentCategoryIsThreeColumnLayout = true

            if (currentCategoryIsThreeColumnLayout !== isThreeColumnLayout) {
              if (
                currentPage.data[0].length > 0 ||
                currentPage.data[1].length > 0 ||
                currentPage.data[2].length > 0
              ) {
                // console.log('Starting a new page due to layout change.')
                pages.push(currentPage)
                currentPage = {
                  data: [[], [], []],
                  isThreeColumnLayout: currentCategoryIsThreeColumnLayout,
                  isFullyFilled: [false, false, false],
                }
                currentPageHeight = [0, 0, 0]
              }
              isThreeColumnLayout = currentCategoryIsThreeColumnLayout
            }

            category.subItems.forEach((item) => {
              const subItemHeader = item?.subcatName
                ? `<h3 class="ml-0 max-w-[345px] w-full font-lato text-black ${
                    isThreeColumnLayout ? 'text-[15px]' : 'text-[17px]'
                  } leading-normal pb-1.5 border-b border-b-gray-300 mt-0 mb-2">${
                    item.subcatName
                  }</h3>`
                : null
              const subItemHeaderHeight = getContentHeight(subItemHeader)
              let subCategoryAdded = false

              // console.log(
              //   `Processing subcategory: ${item.subcatName}, Height: ${subItemHeaderHeight}`
              // )

              item.subItems.forEach((subItem, index) => {
                const isLastItem = index === item.subItems.length - 1

                const subItemContentObj = createItemContent(
                  subItem,
                  isThreeColumnLayout,
                  isLastItem
                )

                // console.log(
                //   `Processing item: ${subItem.itemName}, Height: ${subItemContentObj.height}`
                // )

                const totalHeightColumn1 =
                  currentPageHeight[0] +
                  subItemContentObj.height +
                  (!subCategoryAdded ? subItemHeaderHeight : 0) +
                  (!categoryAdded ? categoryHeaderHeight : 0)
                const totalHeightColumn2 =
                  currentPageHeight[1] +
                  subItemContentObj.height +
                  (!subCategoryAdded ? subItemHeaderHeight : 0) +
                  (!categoryAdded ? categoryHeaderHeight : 0)
                const totalHeightColumn3 =
                  currentPageHeight[2] +
                  subItemContentObj.height +
                  (!subCategoryAdded ? subItemHeaderHeight : 0) +
                  (!categoryAdded ? categoryHeaderHeight : 0)

                if (isThreeColumnLayout) {
                  if (totalHeightColumn1 <= maxPageHeight && !currentPage.isFullyFilled[0]) {
                    // console.log(`Adding to column 1. New height: ${totalHeightColumn1}`)
                    if (!categoryAdded) {
                      currentPage.data[0].push(categoryHeader)
                      currentPageHeight[0] += categoryHeaderHeight
                      categoryAdded = true
                    }
                    if (!subCategoryAdded) {
                      currentPage.data[0].push(subItemHeader)
                      currentPageHeight[0] += subItemHeaderHeight
                      subCategoryAdded = true
                    }
                    currentPage.data[0].push(subItemContentObj.content)
                    currentPageHeight[0] += subItemContentObj.height
                    if (currentPageHeight[0] >= maxPageHeight) {
                      currentPage.isFullyFilled[0] = true
                    }
                  } else if (totalHeightColumn2 <= maxPageHeight && !currentPage.isFullyFilled[1]) {
                    // console.log(`Adding to column 2. New height: ${totalHeightColumn2}`)
                    if (!categoryAdded) {
                      currentPage.data[1].push(categoryHeader)
                      currentPageHeight[1] += categoryHeaderHeight
                      categoryAdded = true
                    }
                    if (!subCategoryAdded) {
                      currentPage.data[1].push(subItemHeader)
                      currentPageHeight[1] += subItemHeaderHeight
                      subCategoryAdded = true
                    }
                    currentPage.data[1].push(subItemContentObj.content)
                    currentPageHeight[1] += subItemContentObj.height
                    if (currentPageHeight[1] >= maxPageHeight) {
                      currentPage.isFullyFilled[1] = true
                    }
                    currentPage.isFullyFilled[0] = true // Mark previous column as filled
                  } else if (totalHeightColumn3 <= maxPageHeight && !currentPage.isFullyFilled[2]) {
                    // console.log(`Adding to column 3. New height: ${totalHeightColumn3}`)
                    if (!categoryAdded) {
                      currentPage.data[2].push(categoryHeader)
                      currentPageHeight[2] += categoryHeaderHeight
                      categoryAdded = true
                    }
                    if (!subCategoryAdded) {
                      currentPage.data[2].push(subItemHeader)
                      currentPageHeight[2] += subItemHeaderHeight
                      subCategoryAdded = true
                    }
                    currentPage.data[2].push(subItemContentObj.content)
                    currentPageHeight[2] += subItemContentObj.height
                    if (currentPageHeight[2] >= maxPageHeight) {
                      currentPage.isFullyFilled[2] = true
                    }
                    currentPage.isFullyFilled[1] = true // Mark previous column as filled
                  } else {
                    // console.log('Starting a new page.')
                    pages.push(currentPage)
                    currentPage = {
                      data: [[], [], []],
                      isThreeColumnLayout: isThreeColumnLayout,
                      isFullyFilled: [false, false, false],
                    }
                    currentPageHeight = [0, 0, 0]

                    // console.log(
                    //   `Adding to column 1 of new page. Height: ${subItemContentObj.height}`
                    // )
                    currentPage.data[0].push(categoryHeader)
                    currentPage.data[0].push(subItemHeader)
                    currentPage.data[0].push(subItemContentObj.content)
                    currentPageHeight[0] +=
                      categoryHeaderHeight + subItemHeaderHeight + subItemContentObj.height
                    categoryAdded = true
                    subCategoryAdded = true
                  }
                } else {
                  if (totalHeightColumn1 <= maxPageHeight && !currentPage.isFullyFilled[0]) {
                    // console.log(`Adding to column 1. New height: ${totalHeightColumn1}`)
                    if (!categoryAdded) {
                      currentPage.data[0].push(categoryHeader)
                      currentPageHeight[0] += categoryHeaderHeight
                      categoryAdded = true
                    }
                    if (!subCategoryAdded) {
                      currentPage.data[0].push(subItemHeader)
                      currentPageHeight[0] += subItemHeaderHeight
                      subCategoryAdded = true
                    }
                    currentPage.data[0].push(subItemContentObj.content)
                    currentPageHeight[0] += subItemContentObj.height
                    if (currentPageHeight[0] >= maxPageHeight) {
                      currentPage.isFullyFilled[0] = true
                    }
                  } else if (totalHeightColumn2 <= maxPageHeight && !currentPage.isFullyFilled[1]) {
                    // console.log(`Adding to column 2. New height: ${totalHeightColumn2}`)
                    if (!categoryAdded) {
                      currentPage.data[1].push(categoryHeader)
                      currentPageHeight[1] += categoryHeaderHeight
                      categoryAdded = true
                    }
                    if (!subCategoryAdded) {
                      currentPage.data[1].push(subItemHeader)
                      currentPageHeight[1] += subItemHeaderHeight
                      subCategoryAdded = true
                    }
                    currentPage.data[1].push(subItemContentObj.content)
                    currentPageHeight[1] += subItemContentObj.height
                    if (currentPageHeight[1] >= maxPageHeight) {
                      currentPage.isFullyFilled[1] = true
                    }
                    currentPage.isFullyFilled[0] = true // Mark previous column as filled
                  } else {
                    // console.log('Starting a new page.')
                    pages.push(currentPage)
                    currentPage = {
                      data: [[], [], []],
                      isThreeColumnLayout: isThreeColumnLayout,
                      isFullyFilled: [false, false, false],
                    }
                    currentPageHeight = [0, 0, 0]

                    // console.log(
                    //   `Adding to column 1 of new page. Height: ${subItemContentObj.height}`
                    // )
                    currentPage.data[0].push(categoryHeader)
                    currentPage.data[0].push(subItemHeader)
                    currentPage.data[0].push(subItemContentObj.content)
                    currentPageHeight[0] +=
                      categoryHeaderHeight + subItemHeaderHeight + subItemContentObj.height
                    categoryAdded = true
                    subCategoryAdded = true
                  }
                }
              })
            })
          }
        })

        if (
          currentPage.data[0].length > 0 ||
          currentPage.data[1].length > 0 ||
          currentPage.data[2].length > 0
        ) {
          // console.log('Adding the last page.')
          pages.push(currentPage)
        }

        // console.log('Total pages:', pages.length)
        return pages
      }

      const pages = distributeContentAcrossPages(data)

      const createPageContent = (page, pageIndex) => {
        const { data, isThreeColumnLayout } = page
        return `
        <div style="height: ${PDF_HEIGHT}px; margin-top: ${PDF_TOP_MARGIN}px;" class='break-after-page relative'>
          <div class="flex flex-row justify-between gap-10">
            <div class="${isThreeColumnLayout ? 'w-[32%]' : 'w-[48%]'} box-border">${data[0].join(
          ''
        )}</div>
            <div class="${isThreeColumnLayout ? 'w-[32%]' : 'w-[48%]'} box-border">${data[1].join(
          ''
        )}</div>
            ${
              isThreeColumnLayout ? `<div class="w-[32%] box-border">${data[2].join('')}</div>` : ''
            }
          </div>
          <div class="text-center absolute -bottom-20 border-t border-t-gray-300 pt-2 left-0 w-full">
            <p class="text-right font-lato text-[9px] text-gray-500">Page ${pageIndex + 1}</p>
          </div>
        </div>
      `
      }

      const createPreviewPageContent = async () => {
        const publishedDate = new Date(
          currentMenu.data.events?.publishedAt ||
            currentMenu.data.events?.editedAt ||
            currentMenu.data.events?.createdAt
        )
        const day = String(publishedDate.getDate()).padStart(2, '0')
        const month = String(publishedDate.getMonth() + 1).padStart(2, '0')
        const year = publishedDate.getFullYear()
        const formattedDate = `${day}.${month}.${year}`

        const qrWifiString = await getWifiQRString(
          currentMenu?.data?.wifi?.ssid,
          currentMenu?.data?.wifi?.password
        )
        const qrWifi = await qr.toDataURL(qrWifiString, {
          type: 'image/png',
        })

        const qrMenuObject = new QRCodeStyling({
          width: 300,
          height: 300,
          type: 'canvas',
          data: `https://${currentMenu?.data?.alias}.gimme.menu`,
          qrOptions: { typeNumber: 0, errorCorrectionLevel: 'Q' },
          imageOptions: { crossOrigin: 'anonymous', imageSize: 0.3 },
          dotsOptions: { type: 'dots', color: '#000' },
          image: templateName === 'premium' ? CameraIcon : '',
          cornersSquareOptions: { type: 'square' },
          cornersDotOptions: { type: 'square' },
        })
        const qrMenu = await blobToDataURL(await qrMenuObject.getRawData('png'))

        const qrReview = currentMenu?.data?.socials?.customLink
          ? await qr.toDataURL(currentMenu?.data?.socials?.customLink, {
              type: 'image/png',
            })
          : ''

        const generateQR = async (content) => {
          return await qr.toDataURL(content, { type: 'image/png' })
        }

        const socialsHTML = await Promise.all(
          SOCIALS_ICONS.map(async (social, index) => {
            const socialName = social?.name
            const socialValue = currentMenu?.data?.socials?.[socialName.toLowerCase()]
            if (!socialName || !socialValue?.length) {
              return ''
            }

            const generatedQr = await generateQR(socialValue)
            return `
            <div key="${index}" class="flex flex-col items-center gap-2">
              <div class="flex items-center justify-center gap-1 relative z-10">
                <img class="mt-1 h-4 w-auto max-w-4" src="./socials/${
                  social?.icon
                }" alt="${socialName}" />
                <p class="mb-2 font-lato text-[11px] text-black leading-normal">${socialName}</p>
              </div>
              ${
                generatedQr
                  ? `<img class="w-[100px] h-[100px]" src="${generatedQr}" alt="qr" />`
                  : ''
              }
            </div>
          `
          })
        )

        return `
        <div
          style="height: ${PDF_HEIGHT}px;"
          class="break-after-page flex flex-col justify-between"
        >
          ${
            currentMenu?.data?.logo
              ? `<img class="mx-auto mt-16 max-w-[300px] w-full" src="https://res.cloudinary.com/gimmemenu/image/upload/f_png,w_500,c_pad/${currentMenu?.data?.logo}" alt="${currentMenu.data.content[0]?.restName}" />`
              : '&nbsp;'
          }
          <div>
          <h1 class="text-center font-lato text-black text-[20px] font-extrabold mb-4">
            Drinks Menu
          </h1>
          <div class="flex justify-between gap-8 max-w-2xl w-full mx-auto items-end">
            <div class="w-[140px] flex flex-col items-center">
              ${
                currentMenu?.data?.wifi?.ssid
                  ? `
                    <div class="flex items-center justify-center gap-1 relative z-10">
                      <img class="mt-1 h-6 w-6" src="./preview/wifi.svg" alt="wifi" />
                      <p class="mb-3 font-lato text-[13px] text-black leading-normal">Scan for Wi-Fi</p>
                    </div>
                    <img class="w-[140px] h-[140px]" src=${qrWifi} alt="wifi" />
                    <div>
                      <p class="text-[10px] text-black font-lato text-center leading-normal">
                        Name: <span class="font-bold">${wifi?.ssid}</span>
                      </p>
                      ${
                        wifi?.password
                          ? `<p class="text-[10px] text-black font-lato text-center leading-normal">
                        Pass: <span class="font-bold">${wifi?.password}</span>
                      </p>`
                          : ''
                      }
                    </div>
                  `
                  : ''
              }
            </div>
    
            <div class="flex flex-col items-center">
              <div class="flex items-center justify-center gap-1 relative z-10">
                <img class="mt-1 h-6 w-6" src="./preview/menu.svg" alt="menu" />
                <p class="mb-3 font-lato text-[13px] text-black leading-normal">${
                  templateName === 'premium' ? 'Scan for Menu with Photos' : 'Scan for Menu'
                }</p>
              </div>
              <img class="w-[240px] h-[240px]" src=${qrMenu} alt="menu" />
            </div>
    
            <div class="w-[140px] flex flex-col items-center">
              ${
                currentMenu?.data?.socials?.customLink?.length
                  ? `
                    <div class="flex flex-col items-center">
                      <div class="flex items-center justify-center gap-1 relative z-10">
                        <img class="mt-1 h-6 w-6" src="./preview/reviews.svg" alt="reviews" />
                        <p class="mb-3 font-lato text-[13px] text-black leading-normal">Scan to Review</p>
                      </div>
                      <img class="w-[140px] h-[140px]" src=${qrReview} alt="reviews" />
                      <p class="text-[10px] text-black font-lato text-center leading-normal">
                        We will appreciate<br />your review :)
                      </p>
                    </div>
                  `
                  : ''
              }
            </div>
          </div>
    
          <div>
            <p class="text-center font-lato text-[9px] leading-normal text-black pb-3 border-b border-b-gray-600">
              Menu updated on ${formattedDate}
            </p>
            <div class="mt-10 flex justify-center gap-14">
              ${socialsHTML.join('')}
            </div>
          </div>
          </div>
        </div>
      `
      }

      const previewHtmlContent = await createPreviewPageContent()
      const htmlPages = pages.map((page, index) => createPageContent(page, index)).join('')
      const htmlContent = `<div>${previewHtmlContent}${htmlPages}</div>`

      usePDFMenu.current.innerHTML = htmlContent

      const pdfBlob = await html2pdf()
        .from(usePDFMenu.current)
        .set({
          margin: 0,
          filename: `${menuName}-${lang}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: {
            scale: 2,
            useCORS: true, // enable cross-origin
            allowTaint: false, // do not taint canvas
          },
          jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        })
        .outputPdf('blob')
      const fileName = menuName?.trim()
        ? `${menuName}-a4-drinks-${lang}.pdf`
        : `menu-a4-drinks-${lang}.pdf`

      pdfsBlob.push({
        name: fileName,
        blob: pdfBlob,
      })
    }

    return pdfsBlob
  }

  const handleSaveFoodsPdfZip = async () => {
    setPdfWidth(PDF_WIDTH_A4)
    const currentMenu = await getMenu(token, user.sub, id)
    if (!currentMenu?.data?.content) {
      console.error('Content is undefined or missing in currentMenu')
      return
    }

    const fallbackContent = {
      ...currentMenu.data.content[0],
      subItems: currentMenu.data.content[0].subItems
        .filter((category) => category.isVisible)
        .map((category) => ({
          ...category,
          subItems: category.subItems
            .filter((subcategory) => subcategory.isVisible)
            .map((subcategory) => ({
              ...subcategory,
              subItems: subcategory.subItems.filter((item) => item.isVisible),
            })),
        })),
    }

    const allData = currentMenu.data.content.map((content) => {
      const data = content.subItems
        .filter((category) => category.isVisible)
        .map((category, categoryIndex) => ({
          catName: category.catName || fallbackContent.subItems[categoryIndex]?.catName || '',
          subItems: category.subItems
            .filter((subCategory) => subCategory.isVisible)
            .map((subCategory, subCategoryIndex) => ({
              subcatName:
                subCategory.subcatName ||
                fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subcatName ||
                '',
              subItems: subCategory.subItems
                .filter((item) => item.isVisible)
                .map((item, itemIndex) => ({
                  itemName:
                    item.itemName ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.itemName ||
                    '',
                  description:
                    item.description ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.description ||
                    '',
                  price:
                    item.price ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.price ||
                    '',
                  subVariant1:
                    item.subVariant1 ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.subVariant1 ||
                    '',
                  subVariant2:
                    item.subVariant2 ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.subVariant2 ||
                    '',
                  subVariant3:
                    item.subVariant3 ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.subVariant3 ||
                    '',
                })),
            })),
        }))
        .flat()

      return {
        data,
        menuName: content.menuName || fallbackContent?.menuName,
        lang: content.lang,
        templateName: currentMenu.data.templateName,
        wifi: currentMenu.data.wifi,
      }
    })

    let pdfsBlob = []

    for (const { data, menuName, lang, templateName, wifi } of allData) {
      const getContentHeight = (content) => {
        const tempDiv = document.createElement('div')
        tempDiv.style.position = 'absolute'
        tempDiv.style.visibility = 'hidden'
        tempDiv.style.width = '100%'
        tempDiv.style.top = '-9999px'
        tempDiv.innerHTML = content
        document.body.appendChild(tempDiv)
        const height = tempDiv.offsetHeight
        document.body.removeChild(tempDiv)
        return height
      }

      const hasSubVariants = (subItem) => {
        return (
          subItem.subVariant1?.values?.length ||
          subItem.subVariant2?.values?.length ||
          subItem.subVariant3?.values?.length
        )
      }

      const createItemContent = (subItem, isThreeColumnLayout, isLastItem) => {
        const content = `
        <div class="max-w-[345px] w-full ${isLastItem ? 'mb-4' : 'mb-2'}">
          <div class="flex flex-row justify-between gap-5 items-center">
            <h4 class="font-lato text-black ${
              isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
            } font-bold leading-normal mb-0">${subItem.itemName}</h4>
            ${
              hasSubVariants(subItem)
                ? ''
                : subItem.price &&
                  `<p class="font-lato ${
                    isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
                  } text-black leading-normal whitespace-nowrap">${subItem.price} ${
                    currentMenu.data.content[0].measureUnits?.currency
                  }</p>`
            }
          </div>
    
          ${
            subItem.description &&
            `<p class="mt-1.5 ml-3 font-lato italic leading-normal ${
              isThreeColumnLayout ? 'text-[11px]' : 'text-[13px]'
            } text-gray-500">${subItem.description}</p>`
          }
          ${
            subItem.subVariant1 && subItem.subVariant1.values.length
              ? subItem.subVariant1.values
                  .map((variant) =>
                    variant.isVisible
                      ? `<div class="ml-3 flex justify-between gap-5 mt-1"><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
                        } text-black font-semibold leading-normal">${
                          variant.subVariantName
                        }</p><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
                        } text-black font-normal leading-normal whitespace-nowrap">${
                          variant.price
                        } ${currentMenu.data.content[0].measureUnits?.currency}</p></div>`
                      : ''
                  )
                  .join('')
              : ''
          }
          ${
            subItem.subVariant2 && subItem.subVariant2.values.length
              ? subItem.subVariant2.values
                  .map((variant) =>
                    variant.isVisible
                      ? `<div class="ml-3 flex justify-between gap-5 mt-1"><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
                        } text-black font-semibold leading-normal">${
                          variant.subVariantName
                        }</p><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
                        } text-black font-normal leading-normal whitespace-nowrap">${
                          variant.price
                        } ${currentMenu.data.content[0].measureUnits?.currency}</p></div>`
                      : ''
                  )
                  .join('')
              : ''
          }
          ${
            subItem.subVariant3 && subItem.subVariant3.values.length
              ? subItem.subVariant3.values
                  .map((variant) =>
                    variant.isVisible
                      ? `<div class="ml-3 flex justify-between gap-5 mt-1"><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[11px]' : 'text-[14px]'
                        } text-black font-semibold leading-normal">${
                          variant.subVariantName
                        }</p><p class="font-lato ${
                          isThreeColumnLayout ? 'text-[12px]' : 'text-[14px]'
                        } text-black font-normal leading-normal whitespace-nowrap">${
                          variant.price
                        } ${currentMenu.data.content[0].measureUnits?.currency}</p></div>`
                      : ''
                  )
                  .join('')
              : ''
          }
        </div>
      `
        return {
          content,
          height: getContentHeight(content),
        }
      }

      const distributeContentAcrossPages = (data) => {
        const pages = []
        let currentPage = {
          data: [[], [], []],
          isThreeColumnLayout: false,
          isFullyFilled: [false, false, false],
        }
        let currentPageHeight = [0, 0, 0]
        const maxPageHeight = PDF_HEIGHT
        let isThreeColumnLayout = false

        data.forEach((category) => {
          if (category.catName !== 'Drinks' && category.catName !== 'Spirits') {
            const categoryHeader = category?.catName
              ? `<h1 class="ml-0 max-w-[345px] w-full font-lato text-black ${
                  isThreeColumnLayout ? 'text-[15px]' : 'text-[17px]'
                } font-extrabold leading-normal mt-0 pb-2 border-b border-b-gray-300 mb-3">${
                  category.catName
                }</h1>`
              : null
            const categoryHeaderHeight = getContentHeight(categoryHeader)
            let categoryAdded = false

            // console.log(`Processing category: ${category.catName}, Height: ${categoryHeaderHeight}`)

            const currentCategoryIsThreeColumnLayout = false

            if (currentCategoryIsThreeColumnLayout !== isThreeColumnLayout) {
              if (
                currentPage.data[0].length > 0 ||
                currentPage.data[1].length > 0 ||
                currentPage.data[2].length > 0
              ) {
                // console.log('Starting a new page due to layout change.')
                pages.push(currentPage)
                currentPage = {
                  data: [[], [], []],
                  isThreeColumnLayout: currentCategoryIsThreeColumnLayout,
                  isFullyFilled: [false, false, false],
                }
                currentPageHeight = [0, 0, 0]
              }
              isThreeColumnLayout = currentCategoryIsThreeColumnLayout
            }

            category.subItems.forEach((item) => {
              const subItemHeader = item?.subcatName
                ? `<h3 class="ml-0 max-w-[345px] w-full font-lato text-black ${
                    isThreeColumnLayout ? 'text-[15px]' : 'text-[17px]'
                  } leading-normal pb-1.5 border-b border-b-gray-300 mt-0 mb-2">${
                    item.subcatName
                  }</h3>`
                : null
              const subItemHeaderHeight = getContentHeight(subItemHeader)
              let subCategoryAdded = false

              // console.log(
              //   `Processing subcategory: ${item.subcatName}, Height: ${subItemHeaderHeight}`
              // )

              item.subItems.forEach((subItem, index) => {
                const isLastItem = index === item.subItems.length - 1

                const subItemContentObj = createItemContent(
                  subItem,
                  isThreeColumnLayout,
                  isLastItem
                )

                // console.log(
                //   `Processing item: ${subItem.itemName}, Height: ${subItemContentObj.height}`
                // )

                const totalHeightColumn1 =
                  currentPageHeight[0] +
                  subItemContentObj.height +
                  (!subCategoryAdded ? subItemHeaderHeight : 0) +
                  (!categoryAdded ? categoryHeaderHeight : 0)
                const totalHeightColumn2 =
                  currentPageHeight[1] +
                  subItemContentObj.height +
                  (!subCategoryAdded ? subItemHeaderHeight : 0) +
                  (!categoryAdded ? categoryHeaderHeight : 0)
                const totalHeightColumn3 =
                  currentPageHeight[2] +
                  subItemContentObj.height +
                  (!subCategoryAdded ? subItemHeaderHeight : 0) +
                  (!categoryAdded ? categoryHeaderHeight : 0)

                if (isThreeColumnLayout) {
                  if (totalHeightColumn1 <= maxPageHeight && !currentPage.isFullyFilled[0]) {
                    // console.log(`Adding to column 1. New height: ${totalHeightColumn1}`)
                    if (!categoryAdded) {
                      currentPage.data[0].push(categoryHeader)
                      currentPageHeight[0] += categoryHeaderHeight
                      categoryAdded = true
                    }
                    if (!subCategoryAdded) {
                      currentPage.data[0].push(subItemHeader)
                      currentPageHeight[0] += subItemHeaderHeight
                      subCategoryAdded = true
                    }
                    currentPage.data[0].push(subItemContentObj.content)
                    currentPageHeight[0] += subItemContentObj.height
                    if (currentPageHeight[0] >= maxPageHeight) {
                      currentPage.isFullyFilled[0] = true
                    }
                  } else if (totalHeightColumn2 <= maxPageHeight && !currentPage.isFullyFilled[1]) {
                    // console.log(`Adding to column 2. New height: ${totalHeightColumn2}`)
                    if (!categoryAdded) {
                      currentPage.data[1].push(categoryHeader)
                      currentPageHeight[1] += categoryHeaderHeight
                      categoryAdded = true
                    }
                    if (!subCategoryAdded) {
                      currentPage.data[1].push(subItemHeader)
                      currentPageHeight[1] += subItemHeaderHeight
                      subCategoryAdded = true
                    }
                    currentPage.data[1].push(subItemContentObj.content)
                    currentPageHeight[1] += subItemContentObj.height
                    if (currentPageHeight[1] >= maxPageHeight) {
                      currentPage.isFullyFilled[1] = true
                    }
                    currentPage.isFullyFilled[0] = true // Mark previous column as filled
                  } else if (totalHeightColumn3 <= maxPageHeight && !currentPage.isFullyFilled[2]) {
                    // console.log(`Adding to column 3. New height: ${totalHeightColumn3}`)
                    if (!categoryAdded) {
                      currentPage.data[2].push(categoryHeader)
                      currentPageHeight[2] += categoryHeaderHeight
                      categoryAdded = true
                    }
                    if (!subCategoryAdded) {
                      currentPage.data[2].push(subItemHeader)
                      currentPageHeight[2] += subItemHeaderHeight
                      subCategoryAdded = true
                    }
                    currentPage.data[2].push(subItemContentObj.content)
                    currentPageHeight[2] += subItemContentObj.height
                    if (currentPageHeight[2] >= maxPageHeight) {
                      currentPage.isFullyFilled[2] = true
                    }
                    currentPage.isFullyFilled[1] = true // Mark previous column as filled
                  } else {
                    // console.log('Starting a new page.')
                    pages.push(currentPage)
                    currentPage = {
                      data: [[], [], []],
                      isThreeColumnLayout: isThreeColumnLayout,
                      isFullyFilled: [false, false, false],
                    }
                    currentPageHeight = [0, 0, 0]

                    // console.log(
                    //   `Adding to column 1 of new page. Height: ${subItemContentObj.height}`
                    // )
                    currentPage.data[0].push(categoryHeader)
                    currentPage.data[0].push(subItemHeader)
                    currentPage.data[0].push(subItemContentObj.content)
                    currentPageHeight[0] +=
                      categoryHeaderHeight + subItemHeaderHeight + subItemContentObj.height
                    categoryAdded = true
                    subCategoryAdded = true
                  }
                } else {
                  if (totalHeightColumn1 <= maxPageHeight && !currentPage.isFullyFilled[0]) {
                    // console.log(`Adding to column 1. New height: ${totalHeightColumn1}`)
                    if (!categoryAdded) {
                      currentPage.data[0].push(categoryHeader)
                      currentPageHeight[0] += categoryHeaderHeight
                      categoryAdded = true
                    }
                    if (!subCategoryAdded) {
                      currentPage.data[0].push(subItemHeader)
                      currentPageHeight[0] += subItemHeaderHeight
                      subCategoryAdded = true
                    }
                    currentPage.data[0].push(subItemContentObj.content)
                    currentPageHeight[0] += subItemContentObj.height
                    if (currentPageHeight[0] >= maxPageHeight) {
                      currentPage.isFullyFilled[0] = true
                    }
                  } else if (totalHeightColumn2 <= maxPageHeight && !currentPage.isFullyFilled[1]) {
                    // console.log(`Adding to column 2. New height: ${totalHeightColumn2}`)
                    if (!categoryAdded) {
                      currentPage.data[1].push(categoryHeader)
                      currentPageHeight[1] += categoryHeaderHeight
                      categoryAdded = true
                    }
                    if (!subCategoryAdded) {
                      currentPage.data[1].push(subItemHeader)
                      currentPageHeight[1] += subItemHeaderHeight
                      subCategoryAdded = true
                    }
                    currentPage.data[1].push(subItemContentObj.content)
                    currentPageHeight[1] += subItemContentObj.height
                    if (currentPageHeight[1] >= maxPageHeight) {
                      currentPage.isFullyFilled[1] = true
                    }
                    currentPage.isFullyFilled[0] = true // Mark previous column as filled
                  } else {
                    // console.log('Starting a new page.')
                    pages.push(currentPage)
                    currentPage = {
                      data: [[], [], []],
                      isThreeColumnLayout: isThreeColumnLayout,
                      isFullyFilled: [false, false, false],
                    }
                    currentPageHeight = [0, 0, 0]

                    // console.log(
                    //   `Adding to column 1 of new page. Height: ${subItemContentObj.height}`
                    // )
                    currentPage.data[0].push(categoryHeader)
                    currentPage.data[0].push(subItemHeader)
                    currentPage.data[0].push(subItemContentObj.content)
                    currentPageHeight[0] +=
                      categoryHeaderHeight + subItemHeaderHeight + subItemContentObj.height
                    categoryAdded = true
                    subCategoryAdded = true
                  }
                }
              })
            })
          }
        })

        if (
          currentPage.data[0].length > 0 ||
          currentPage.data[1].length > 0 ||
          currentPage.data[2].length > 0
        ) {
          // console.log('Adding the last page.')
          pages.push(currentPage)
        }

        // console.log('Total pages:', pages.length)
        return pages
      }

      const pages = distributeContentAcrossPages(data)

      const createPageContent = (page, pageIndex) => {
        const { data, isThreeColumnLayout } = page
        return `
        <div style="height: ${PDF_HEIGHT}px; margin-top: ${PDF_TOP_MARGIN}px;" class='break-after-page relative'>
          <div class="flex flex-row justify-between gap-10">
            <div class="${isThreeColumnLayout ? 'w-[32%]' : 'w-[48%]'} box-border">${data[0].join(
          ''
        )}</div>
            <div class="${isThreeColumnLayout ? 'w-[32%]' : 'w-[48%]'} box-border">${data[1].join(
          ''
        )}</div>
            ${
              isThreeColumnLayout ? `<div class="w-[32%] box-border">${data[2].join('')}</div>` : ''
            }
          </div>
          <div class="text-center absolute -bottom-20 border-t border-t-gray-300 pt-2 left-0 w-full">
            <p class="text-right font-lato text-[9px] text-gray-500">Page ${pageIndex + 1}</p>
          </div>
        </div>
      `
      }

      const createPreviewPageContent = async () => {
        const publishedDate = new Date(
          currentMenu.data.events?.publishedAt ||
            currentMenu.data.events?.editedAt ||
            currentMenu.data.events?.createdAt
        )
        const day = String(publishedDate.getDate()).padStart(2, '0')
        const month = String(publishedDate.getMonth() + 1).padStart(2, '0')
        const year = publishedDate.getFullYear()
        const formattedDate = `${day}.${month}.${year}`

        const qrWifiString = await getWifiQRString(
          currentMenu?.data?.wifi?.ssid,
          currentMenu?.data?.wifi?.password
        )
        const qrWifi = await qr.toDataURL(qrWifiString, {
          type: 'image/png',
        })

        const qrMenuObject = new QRCodeStyling({
          width: 300,
          height: 300,
          type: 'canvas',
          data: `https://${currentMenu?.data?.alias}.gimme.menu`,
          qrOptions: { typeNumber: 0, errorCorrectionLevel: 'Q' },
          imageOptions: { crossOrigin: 'anonymous', imageSize: 0.3 },
          dotsOptions: { type: 'dots', color: '#000' },
          image: templateName === 'premium' ? CameraIcon : '',
          cornersSquareOptions: { type: 'square' },
          cornersDotOptions: { type: 'square' },
        })
        const qrMenu = await blobToDataURL(await qrMenuObject.getRawData('png'))

        const qrReview = currentMenu?.data?.socials?.customLink
          ? await qr.toDataURL(currentMenu?.data?.socials?.customLink, {
              type: 'image/png',
            })
          : ''

        const generateQR = async (content) => {
          return await qr.toDataURL(content, { type: 'image/png' })
        }

        const socialsHTML = await Promise.all(
          SOCIALS_ICONS.map(async (social, index) => {
            const socialName = social?.name
            const socialValue = currentMenu?.data?.socials?.[socialName.toLowerCase()]
            if (!socialName || !socialValue?.length) {
              return ''
            }

            const generatedQr = await generateQR(socialValue)
            return `
            <div key="${index}" class="flex flex-col items-center gap-2">
              <div class="flex items-center justify-center gap-1 relative z-10">
                <img class="mt-1 h-4 w-auto max-w-4" src="./socials/${
                  social?.icon
                }" alt="${socialName}" />
                <p class="mb-2 font-lato text-[11px] text-black leading-normal">${socialName}</p>
              </div>
              ${
                generatedQr
                  ? `<img class="w-[100px] h-[100px]" src="${generatedQr}" alt="qr" />`
                  : ''
              }
            </div>
          `
          })
        )

        return `
        <div
        style="height: ${PDF_HEIGHT}px;"
          class="break-after-page flex flex-col justify-between"
        >
          ${
            currentMenu?.data?.logo
              ? `<img class="mx-auto mt-16 max-w-[300px] w-full" src="https://res.cloudinary.com/gimmemenu/image/upload/f_png,w_500,c_pad/${currentMenu?.data?.logo}" alt="${currentMenu.data.content[0]?.restName}" />`
              : '&nbsp;'
          }
          <div>
          <h1 class="text-center font-lato text-black text-[20px] font-extrabold mb-4">
            Foods Menu
          </h1>

          <div class="flex justify-between gap-8 max-w-2xl w-full mx-auto items-end">
            <div class="w-[140px] flex flex-col items-center">
              ${
                currentMenu?.data?.wifi?.ssid
                  ? `
                    <div class="flex items-center justify-center gap-1 relative z-10">
                      <img class="mt-1 h-6 w-6" src="./preview/wifi.svg" alt="wifi" />
                      <p class="mb-3 font-lato text-[13px] text-black leading-normal">Scan for Wi-Fi</p>
                    </div>
                    <img class="w-[140px] h-[140px]" src=${qrWifi} alt="wifi" />
                    <div>
                      <p class="text-[10px] text-black font-lato text-center leading-normal">
                        Name: <span class="font-bold">${wifi?.ssid}</span>
                      </p>
                      ${
                        wifi?.password
                          ? `<p class="text-[10px] text-black font-lato text-center leading-normal">
                        Pass: <span class="font-bold">${wifi?.password}</span>
                      </p>`
                          : ''
                      }
                    </div>
                  `
                  : ''
              }
            </div>
    
            <div class="flex flex-col items-center">
              <div class="flex items-center justify-center gap-1 relative z-10">
                <img class="mt-1 h-6 w-6" src="./preview/menu.svg" alt="menu" />
                <p class="mb-3 font-lato text-[13px] text-black leading-normal">${
                  templateName === 'premium' ? 'Scan for Menu with Photos' : 'Scan for Menu'
                }</p>
              </div>
              <img class="w-[240px] h-[240px]" src=${qrMenu} alt="menu" />
            </div>
    
            <div class="w-[140px] flex flex-col items-center">
              ${
                currentMenu?.data?.socials?.customLink?.length
                  ? `
                    <div class="flex flex-col items-center">
                      <div class="flex items-center justify-center gap-1 relative z-10">
                        <img class="mt-1 h-6 w-6" src="./preview/reviews.svg" alt="reviews" />
                        <p class="mb-3 font-lato text-[13px] text-black leading-normal">Scan to Review</p>
                      </div>
                      <img class="w-[140px] h-[140px]" src=${qrReview} alt="reviews" />
                      <p class="text-[10px] text-black font-lato text-center leading-normal">
                        We will appreciate<br />your review :)
                      </p>
                    </div>
                  `
                  : ''
              }
            </div>
          </div>
    
          <div>
            <p class="text-center font-lato text-[9px] leading-normal text-black pb-3 border-b border-b-gray-600">
              Menu updated on ${formattedDate}
            </p>
            <div class="mt-10 flex justify-center gap-14">
              ${socialsHTML.join('')}
            </div>
          </div>
          </div>
        </div>
      `
      }

      const previewHtmlContent = await createPreviewPageContent()
      const htmlPages = pages.map((page, index) => createPageContent(page, index)).join('')
      const htmlContent = `<div>${previewHtmlContent}${htmlPages}</div>`

      usePDFMenu.current.innerHTML = htmlContent

      const pdfBlob = await html2pdf()
        .from(usePDFMenu.current)
        .set({
          margin: 0,
          filename: `${menuName}-${lang}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: {
            scale: 2,
            useCORS: true, // enable cross-origin
            allowTaint: false, // do not taint canvas
          },
          jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
        })
        .outputPdf('blob')

      const fileName = menuName?.trim()
        ? `${menuName}-a4-foods-${lang}.pdf`
        : `menu-a4-foods-${lang}.pdf`

      pdfsBlob.push({
        name: fileName,
        blob: pdfBlob,
      })
    }

    return pdfsBlob
  }

  const handleSavePdfA5Zip = async () => {
    setPdfWidth(PDF_WIDTH_A5)
    const currentMenu = await getMenu(token, user.sub, id)

    if (!currentMenu?.data?.content) {
      console.error('Content is undefined or missing in currentMenu')
      return
    }

    const fallbackContent = {
      ...currentMenu.data.content[0],
      subItems: currentMenu.data.content[0].subItems
        .filter((category) => category.isVisible)
        .map((category) => ({
          ...category,
          subItems: category.subItems
            .filter((subcategory) => subcategory.isVisible)
            .map((subcategory) => ({
              ...subcategory,
              subItems: subcategory.subItems.filter((item) => item.isVisible),
            })),
        })),
    }

    const allData = currentMenu.data.content.map((content) => {
      const data = content.subItems
        .filter((category) => category.isVisible)
        .map((category, categoryIndex) => ({
          catName: category.catName || fallbackContent.subItems[categoryIndex]?.catName || '',
          subItems: category.subItems
            .filter((subCategory) => subCategory.isVisible)
            .map((subCategory, subCategoryIndex) => ({
              subcatName:
                subCategory.subcatName ||
                fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subcatName ||
                '',
              subItems: subCategory.subItems
                .filter((item) => item.isVisible)
                .map((item, itemIndex) => ({
                  itemName:
                    item.itemName ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.itemName ||
                    '',
                  description:
                    item.description ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.description ||
                    '',
                  price:
                    item.price ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.price ||
                    '',
                  subVariant1:
                    item.subVariant1 ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.subVariant1 ||
                    '',
                  subVariant2:
                    item.subVariant2 ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.subVariant2 ||
                    '',
                  subVariant3:
                    item.subVariant3 ||
                    fallbackContent.subItems[categoryIndex]?.subItems[subCategoryIndex]?.subItems[
                      itemIndex
                    ]?.subVariant3 ||
                    '',
                })),
            })),
        }))
        .flat()

      return {
        data,
        menuName: content.menuName || fallbackContent?.menuName,
        lang: content.lang,
        templateName: currentMenu.data.templateName,
        wifi: currentMenu.data.wifi,
      }
    })

    let pdfsBlob = []

    for (const { data, menuName, lang, templateName, wifi } of allData) {
      const getContentHeight = (content) => {
        const tempDiv = document.createElement('div')
        tempDiv.style.position = 'absolute'
        tempDiv.style.visibility = 'hidden'
        tempDiv.style.width = '100%'
        tempDiv.style.top = '-9999px'
        tempDiv.innerHTML = content
        document.body.appendChild(tempDiv)
        const height = tempDiv.offsetHeight
        document.body.removeChild(tempDiv)
        return height
      }

      const hasSubVariants = (subItem) => {
        return (
          subItem.subVariant1?.values?.length ||
          subItem.subVariant2?.values?.length ||
          subItem.subVariant3?.values?.length
        )
      }

      const createItemContent = (subItem, isLastItem) => {
        const content = `
        <div class="w-full ${isLastItem ? 'mb-4' : 'mb-2'}">
          <div class="flex flex-row justify-between gap-5 items-center">
            <h4 class="font-lato text-black text-[14px] font-bold leading-normal mb-0">${
              subItem.itemName
            }</h4>
            ${
              hasSubVariants(subItem)
                ? ''
                : subItem.price &&
                  `<p class="font-lato text-[14px] text-black leading-normal whitespace-nowrap">${subItem.price} ${currentMenu.data.content[0].measureUnits?.currency}</p>`
            }
          </div>
    
          ${
            subItem.description &&
            `<p class="mt-1.5 ml-3 font-lato italic leading-normal text-[13px] text-gray-500">${subItem.description}</p>`
          }
          ${
            subItem.subVariant1 && subItem.subVariant1.values.length
              ? subItem.subVariant1.values
                  .map((variant) =>
                    variant.isVisible
                      ? `<div class="ml-3 flex justify-between gap-5 mt-1"><p class="font-lato text-[14px] text-black font-semibold leading-normal">${variant.subVariantName}</p><p class="font-lato text-[14px] text-black font-normal leading-normal whitespace-nowrap">${variant.price} ${currentMenu.data.content[0].measureUnits?.currency}</p></div>`
                      : ''
                  )
                  .join('')
              : ''
          }
          ${
            subItem.subVariant2 && subItem.subVariant2.values.length
              ? subItem.subVariant2.values
                  .map((variant) =>
                    variant.isVisible
                      ? `<div class="ml-3 flex justify-between gap-5 mt-1"><p class="font-lato text-[14px] text-black font-semibold leading-normal">${variant.subVariantName}</p><p class="font-lato text-[14px] text-black font-normal leading-normal whitespace-nowrap">${variant.price} ${currentMenu.data.content[0].measureUnits?.currency}</p></div>`
                      : ''
                  )
                  .join('')
              : ''
          }
          ${
            subItem.subVariant3 && subItem.subVariant3.values.length
              ? subItem.subVariant3.values
                  .map((variant) =>
                    variant.isVisible
                      ? `<div class="ml-3 flex justify-between gap-5 mt-1"><p class="font-lato text-[14px] text-black font-semibold leading-normal">${variant.subVariantName}</p><p class="font-lato text-[14px] text-black font-normal leading-normal whitespace-nowrap">${variant.price} ${currentMenu.data.content[0].measureUnits?.currency}</p></div>`
                      : ''
                  )
                  .join('')
              : ''
          }
        </div>
      `
        return {
          content,
          height: getContentHeight(content),
        }
      }

      const distributeContentAcrossPages = (data) => {
        const pages = []
        let currentPage = {
          data: [[], []],
          isTwoColumnLayout: true,
          isFullyFilled: [false, false],
        }
        let currentPageHeight = [0, 0]
        const maxPageHeight = PDF_HEIGHT_A5

        data.forEach((category) => {
          if (category.catName === 'Drinks' || category.catName === 'Spirits') {
            const categoryHeader = category?.catName
              ? `<h1 class="ml-0 max-w-[280px] w-full font-lato text-black text-[17px] font-extrabold leading-normal mt-0 pb-2 border-b border-b-gray-300 mb-3">${category.catName}</h1>`
              : null
            const categoryHeaderHeight = getContentHeight(categoryHeader)
            let categoryAdded = false

            // console.log(`Processing category: ${category.catName}, Height: ${categoryHeaderHeight}`)

            category.subItems.forEach((item) => {
              const subItemHeader = item?.subcatName
                ? `<h3 class="ml-0 max-w-[280px] w-full font-lato text-black text-[17px] leading-normal pb-1.5 border-b border-b-gray-300 mt-0 mb-2">${item.subcatName}</h3>`
                : null
              const subItemHeaderHeight = getContentHeight(subItemHeader)
              let subCategoryAdded = false

              // console.log(
              //   `Processing subcategory: ${item.subcatName}, Height: ${subItemHeaderHeight}`
              // )

              item.subItems.forEach((subItem, index) => {
                const isLastItem = index === item.subItems.length - 1

                const subItemContentObj = createItemContent(subItem, isLastItem)

                // console.log(
                //   `Processing item: ${subItem.itemName}, Height: ${subItemContentObj.height}`
                // )

                const totalHeightColumn1 =
                  currentPageHeight[0] +
                  subItemContentObj.height +
                  (!subCategoryAdded ? subItemHeaderHeight : 0) +
                  (!categoryAdded ? categoryHeaderHeight : 0)
                const totalHeightColumn2 =
                  currentPageHeight[1] +
                  subItemContentObj.height +
                  (!subCategoryAdded ? subItemHeaderHeight : 0) +
                  (!categoryAdded ? categoryHeaderHeight : 0)

                if (totalHeightColumn1 <= maxPageHeight && !currentPage.isFullyFilled[0]) {
                  // console.log(`Adding to column 1. New height: ${totalHeightColumn1}`)
                  if (!categoryAdded) {
                    currentPage.data[0].push(categoryHeader)
                    currentPageHeight[0] += categoryHeaderHeight
                    categoryAdded = true
                  }
                  if (!subCategoryAdded) {
                    currentPage.data[0].push(subItemHeader)
                    currentPageHeight[0] += subItemHeaderHeight
                    subCategoryAdded = true
                  }
                  currentPage.data[0].push(subItemContentObj.content)
                  currentPageHeight[0] += subItemContentObj.height
                  if (currentPageHeight[0] >= maxPageHeight) {
                    currentPage.isFullyFilled[0] = true
                  }
                } else if (totalHeightColumn2 <= maxPageHeight && !currentPage.isFullyFilled[1]) {
                  // console.log(`Adding to column 2. New height: ${totalHeightColumn2}`)
                  if (!categoryAdded) {
                    currentPage.data[1].push(categoryHeader)
                    currentPageHeight[1] += categoryHeaderHeight
                    categoryAdded = true
                  }
                  if (!subCategoryAdded) {
                    currentPage.data[1].push(subItemHeader)
                    currentPageHeight[1] += subItemHeaderHeight
                    subCategoryAdded = true
                  }
                  currentPage.data[1].push(subItemContentObj.content)
                  currentPageHeight[1] += subItemContentObj.height
                  if (currentPageHeight[1] >= maxPageHeight) {
                    currentPage.isFullyFilled[1] = true
                  }
                } else {
                  // console.log('Starting a new page.')
                  pages.push(currentPage)
                  currentPage = {
                    data: [[], []],
                    isTwoColumnLayout: true,
                    isFullyFilled: [false, false],
                  }
                  currentPageHeight = [0, 0]
                  // console.log(`Adding to column 1 of new page. Height: ${subItemContentObj.height}`)
                  currentPage.data[0].push(categoryHeader)
                  currentPage.data[0].push(subItemHeader)
                  currentPage.data[0].push(subItemContentObj.content)
                  currentPageHeight[0] +=
                    categoryHeaderHeight + subItemHeaderHeight + subItemContentObj.height
                  categoryAdded = true
                  subCategoryAdded = true
                }
              })
            })
          }
        })

        if (currentPage.data[0].length > 0 || currentPage.data[1].length > 0) {
          // console.log('Adding the last page.')
          pages.push(currentPage)
        }

        // console.log('Total pages:', pages.length)
        return pages
      }

      const pages = distributeContentAcrossPages(data)

      const createPageContent = (page, pageIndex) => {
        const { data } = page

        return `
        <div style="height: ${PDF_HEIGHT_A5}px; margin-top: ${PDF_TOP_MARGIN_A5}px;" class='break-after-page relative'>
          <div class="flex flex-row justify-between gap-5">
            <div class="w-[50%] box-border">${data[0].join('')}</div>
            <div class="w-[50%] box-border">${data[1].join('')}</div>
          </div>
          <div class="text-center absolute -bottom-20 border-t border-t-gray-300 pt-2 left-0 w-full">
            <p class="text-right font-lato text-[9px] text-gray-500">Page ${pageIndex + 1}</p>
          </div>
        </div>
      `
      }

      const createPreviewPageContent = async () => {
        const publishedDate = new Date(
          currentMenu.data.events?.publishedAt ||
            currentMenu.data.events?.editedAt ||
            currentMenu.data.events?.createdAt
        )
        const day = String(publishedDate.getDate()).padStart(2, '0')
        const month = String(publishedDate.getMonth() + 1).padStart(2, '0')
        const year = publishedDate.getFullYear()
        const formattedDate = `${day}.${month}.${year}`

        const qrWifiString = await getWifiQRString(
          currentMenu?.data?.wifi?.ssid,
          currentMenu?.data?.wifi?.password
        )
        const qrWifi = await qr.toDataURL(qrWifiString, {
          type: 'image/png',
        })

        const qrMenuObject = new QRCodeStyling({
          width: 300,
          height: 300,
          type: 'canvas',
          data: `https://${currentMenu?.data?.alias}.gimme.menu`,
          qrOptions: { typeNumber: 0, errorCorrectionLevel: 'Q' },
          imageOptions: { crossOrigin: 'anonymous', imageSize: 0.3 },
          dotsOptions: { type: 'dots', color: '#000' },
          image: templateName === 'premium' ? CameraIcon : '',
          cornersSquareOptions: { type: 'square' },
          cornersDotOptions: { type: 'square' },
        })
        const qrMenu = await blobToDataURL(await qrMenuObject.getRawData('png'))

        const qrReview = currentMenu?.data?.socials?.customLink
          ? await qr.toDataURL(currentMenu?.data?.socials?.customLink, {
              type: 'image/png',
            })
          : ''

        const generateQR = async (content) => {
          return await qr.toDataURL(content, { type: 'image/png' })
        }

        const socialsHTML = await Promise.all(
          SOCIALS_ICONS.map(async (social, index) => {
            const socialName = social?.name
            const socialValue = currentMenu?.data?.socials?.[socialName.toLowerCase()]
            if (!socialName || !socialValue?.length) {
              return ''
            }

            const generatedQr = await generateQR(socialValue)
            return `
            <div key="${index}" class="flex flex-col items-center gap-2">
              <div class="flex items-center justify-center gap-1 relative z-10">
                <img class="mt-1 h-4 w-auto max-w-4" src="./socials/${
                  social?.icon
                }" alt="${socialName}" />
                <p class="mb-2 font-lato text-[11px] text-black leading-normal">${socialName}</p>
              </div>
              ${
                generatedQr ? `<img class="w-[80px] h-[80px]" src="${generatedQr}" alt="qr" />` : ''
              }
            </div>
          `
          })
        )

        return `
        <div
    style="height: ${PDF_HEIGHT_A5}px; "
          class="break-after-page flex flex-col justify-between"
        >
          ${
            currentMenu?.data?.logo
              ? `<img class="mx-auto mt-16 max-w-[150px] w-full" src="https://res.cloudinary.com/gimmemenu/image/upload/f_png,w_500,c_pad/${currentMenu?.data?.logo}" alt="${currentMenu.data.content[0]?.restName}" />`
              : '&nbsp;'
          }
          <div>

          <h1 class="text-center font-lato text-black text-[20px] font-extrabold mb-4">
            Drinks Menu
          </h1>

          <div class="flex justify-between gap-8 max-w-2xl w-full mx-auto items-end">
            <div class="w-[80px] flex flex-col items-center">
              ${
                currentMenu?.data?.wifi?.ssid
                  ? `
                    <div class="flex items-center justify-center gap-1 relative z-10">
                      <img class="mt-1 h-6 w-6" src="./preview/wifi.svg" alt="wifi" />
                      <p class="mb-3 font-lato text-[13px] text-black leading-normal">Scan for Wi-Fi</p>
                    </div>
                    <img class="w-[80px] h-[80px]" src=${qrWifi} alt="wifi" />
                    <div>
                      <p class="text-[10px] text-black font-lato text-center leading-normal">
                        Name: <span class="font-bold">${wifi?.ssid}</span>
                      </p>
                      ${
                        wifi?.password
                          ? `<p class="text-[10px] text-black font-lato text-center leading-normal">
                        Pass: <span class="font-bold">${wifi?.password}</span>
                      </p>`
                          : ''
                      }
                    </div>
                  `
                  : ''
              }
            </div>
    
            <div class="flex flex-col items-center">
              <div class="flex items-center justify-center gap-1 relative z-10">
                <img class="mt-1 h-6 w-6" src="./preview/menu.svg" alt="menu" />
                <p class="mb-3 font-lato text-[13px] text-black leading-normal">${
                  templateName === 'premium' ? 'Scan for Menu with Photos' : 'Scan for Menu'
                }</p>
              </div>
              <img class="w-[150px] h-[150px]" src=${qrMenu} alt="menu" />
            </div>
    
            <div class="w-[80px] flex flex-col items-center">
              ${
                currentMenu?.data?.socials?.customLink?.length
                  ? `
                    <div class="flex flex-col items-center">
                      <div class="flex items-center justify-center gap-1 relative z-10">
                        <img class="mt-1 h-6 w-6" src="./preview/reviews.svg" alt="reviews" />
                        <p class="mb-3 font-lato text-[13px] text-black leading-normal">Scan to Review</p>
                      </div>
                      <img class="w-[80px] h-[80px]" src=${qrReview} alt="reviews" />
                      <p class="text-[10px] text-black font-lato text-center leading-normal">
                        We will appreciate<br />your review :)
                      </p>
                    </div>
                  `
                  : ''
              }
            </div>
          </div>
    
          <div>
            <p class="text-center font-lato text-[9px] leading-normal text-black pb-3 border-b border-b-gray-600">
              Menu updated on ${formattedDate}
            </p>
            <div class="mt-10 flex justify-center gap-14">
              ${socialsHTML.join('')}
            </div>
          </div>
          </div>
        </div>
      `
      }

      const previewHtmlContent = await createPreviewPageContent()
      const htmlPages = pages.map((page, index) => createPageContent(page, index)).join('')
      const htmlContent = `<div>${previewHtmlContent}${htmlPages}</div>`

      usePDFMenu.current.innerHTML = htmlContent

      const pdfBlob = await html2pdf()
        .from(usePDFMenu.current)
        .set({
          margin: 0,
          filename: `${menuName}-${lang}.pdf`,
          image: { type: 'jpeg', quality: 0.98 },
          html2canvas: {
            scale: 2,
            useCORS: true, // enable cross-origin
            allowTaint: false, // do not taint canvas
          },
          jsPDF: { unit: 'in', format: 'a5', orientation: 'portrait' },
        })
        .outputPdf('blob')

      const fileName = menuName?.trim()
        ? `${menuName}-a5-drinks-${lang}.pdf`
        : `menu-a5-drinks-${lang}.pdf`

      pdfsBlob.push({
        name: fileName,
        blob: pdfBlob,
      })
    }

    return pdfsBlob
  }

  const handleSaveAllPdfsZip = async () => {
    const allZip = new JSZip()

    const pdfsBlobMain = await handleSavePdfZip()
    const pdfsBlobDrinks = await handleSaveDrinksPdfZip()
    const pdfsBlobFoods = await handleSaveFoodsPdfZip()
    const pdfsBlobA5 = await handleSavePdfA5Zip()

    if (pdfsBlobMain.length) {
      pdfsBlobMain.map((pdf) => {
        allZip.file(pdf.name, pdf.blob)
      })
    }
    if (pdfsBlobDrinks.length) {
      pdfsBlobDrinks.map((pdf) => {
        allZip.file(pdf.name, pdf.blob)
      })
    }
    if (pdfsBlobFoods.length) {
      pdfsBlobFoods.map((pdf) => {
        allZip.file(pdf.name, pdf.blob)
      })
    }
    if (pdfsBlobA5.length) {
      pdfsBlobA5.map((pdf) => {
        allZip.file(pdf.name, pdf.blob)
      })
    }

    const zipBlob = await allZip.generateAsync({ type: 'blob' })

    const a = document.createElement('a')
    a.href = URL.createObjectURL(zipBlob)
    a.setAttribute('download', 'all-menus.zip')
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  const getEditMenuButton = () => (
    <>
      {data ? (
        <div className='v2-button sm' onClick={() => handleRedirect()}>
          <IconPen color='#ffffff' width={isDesktop ? '24px' : '20px'} />
          {t('MANAGER_MENU_ITEM_BUTTON_EDIT')}
        </div>
      ) : (
        <div className='skeleton-button sm'>
          <Skeleton />
        </div>
      )}
    </>
  )

  const getCloneMenuButton = () => (
    <>
      {data ? (
        <div className='v2-button sm' onClick={handleCloneMenu}>
          <IconCloneMenu color='#ffffff' width={isDesktop ? '24px' : '20px'} />
          {t('MANAGER_MENU_ITEM_BUTTON_CLONE')}
        </div>
      ) : (
        <div className='skeleton-button sm'>
          <Skeleton />
        </div>
      )}
    </>
  )

  const getDeleteMenuButton = () => (
    <>
      {data ? (
        <div
          className='v2-button sm'
          onClick={() => {
            setShowRemoveModal({
              show: true,
              name: alias,
              kind: t('CON_MODAL_DELETE_MENU'),
              cbName: handleDeleteMenu,
              cbArgs: [],
            })
          }}
        >
          <IconRemoveItem color='#ffffff' width={isDesktop ? '24px' : '20px'} />
          {t('MANAGER_MENU_ITEM_BUTTON_DELETE')}
        </div>
      ) : (
        <div className='skeleton-button sm'>
          <Skeleton />
        </div>
      )}
    </>
  )

  const getLogo = () => (
    <div className='logo'>
      {data ? (
        <>
          {logo ? (
            <img src={`${REACT_APP_CLOUDINARY_LOGO_PATH}${logo}`} alt='logo' />
          ) : (
            <DefaultLogo
              width={isDesktop ? '300px' : '100px'}
              height={isDesktop ? '300px' : '100px'}
            />
          )}
        </>
      ) : (
        <Skeleton />
      )}
    </div>
  )

  const getTranslationsList = () => {
    return (
      <>
        {data
          ? translations.length > 1 && (
              <div
                aria-expanded={isOpen}
                className={`translations${isOpen ? ' open' : ''}`}
                onClick={() => setIsOpen(!isOpen)}
              >
                <div className='translations-title'>
                  <h4>{t('MANAGER_MENU_ITEM_DROPDOWN_TEXT')}</h4>
                </div>
                {translations
                  .filter((item) => item.lang !== defaultLanguage)
                  .map((translation, t) => (
                    <MenuLanguageBlock
                      key={t}
                      menuId={id}
                      langName={translation.lang}
                      isPub={translation.isPub}
                      url={`${url}?lang=${translation.lang}`}
                      handleSaveQrZip={handleSaveQrZip}
                    />
                  ))}
              </div>
            )
          : null}
      </>
    )
  }

  const getMobileItem = () => (
    <div className='content-inner'>
      <div className='default-content'>
        <div className='content'>
          {alias ? (
            <h3>{alias}</h3>
          ) : (
            <h3>
              <Skeleton />
            </h3>
          )}
          {getEditMenuButton()}
        </div>
        {getLogo()}
      </div>
      <MenuLanguageBlock
        menuId={id}
        langName={defaultLanguage}
        isPub={!!events?.publishedAt}
        url={url}
        handleSaveQrZip={handleSaveQrZip}
      />
      {getTranslationsList()}
      <div className='delete-clone-btns'>
        {getDeleteMenuButton()}
        {getCloneMenuButton()}
      </div>
      <TheIndicator events={events} />
    </div>
  )

  const getDesktopItem = () => (
    <>
      <div className='content-block'>
        <div className='name-and-edit'>
          {alias ? (
            <h3>{alias}</h3>
          ) : (
            <h3>
              <Skeleton />
            </h3>
          )}
          <div className='button-block'>
            {getEditMenuButton()}
            {getCloneMenuButton()}
            {getDeleteMenuButton()}
          </div>
        </div>
        <MenuLanguageBlock
          menuId={id}
          langName={defaultLanguage}
          isPub={!!events?.publishedAt}
          url={url}
          handleSaveQrZip={handleSaveQrZip}
          handleSaveMdZip={handleSaveMdZip}
          handleSaveAllPdfsZip={handleSaveAllPdfsZip}
        />
        {getTranslationsList()}
      </div>
      <div className='logo-block'>
        <TheIndicator events={events} />
        {getLogo()}
      </div>
    </>
  )

  return (
    <>
      <div aria-expanded={isOpen} className='menu-item relative'>
        {isDesktop ? getDesktopItem() : getMobileItem()}
      </div>
      <div className='z-50 fixed w-full top-0 left-0 bg-white hidden'>
        {/* <div className='z-50 absolute w-full top-0 left-0 bg-white'> */}
        <div
          ref={usePDFMenu}
          style={{ maxWidth: `${pdfWidth}px` }}
          className='w-full mx-auto'
        ></div>
      </div>
    </>
  )
}

export default withRouter(MenuItem)
